export const jobs = [
  {
    id: 'developer',
    title: 'Lead Software Engineer - Developer Infrastructure',
    place: 'Melbourne, MEL',
    type: 'Engineering',
    desc: `<div class="job__description body">
      
      <div>


        <p><strong>RESPONSIBILITIES:</strong></p>
        <ul>
          <li>Work closely with engineering, product and research teams to understand and document our technologies and
            product (e.g. open source software documentation, API doc, user education content and etc), and provide
            documentation and content to a fast growing set of audience including end users, community members and
            developers.
          </li>
          <li>Design how existing and new content should be organized and presented to make content easier to understand
            and more discoverable
          </li>
          <li>Measure documentation effectiveness to identify gaps for areas of improvement</li>
          <li>Improve the content quality by obtaining both high-level and in-depth understanding of the product and
            technical details.
          </li>
          <li>Deliver content in various formats, including but not limited to written documentation, blog posts, public
            code repositories and video content
          </li>
          <li>Ensure that management and project stakeholders are well-informed of project status, including conflicts
            and resolution plans
          </li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li>5+ years of experience as a technical writer on complex products/ technical projects (preferably in
            blockchain space)
          </li>
          <li>Excellent writing and proofreading skills in English language</li>
          <li>Experience owning the end-to-end documentation creation and management process</li>
          <li>Experience and proven ability in self-managing a high volume of tasks, priorities, and set yourself high
            standard of accuracy, proficiency and precision
          </li>
          <li>Experience in collaborating with a wide range of people, teams and community members</li>
          <li>Client-focused, partnering approach with both our business leaders and engineers to deliver a first-class
            experience for all stakeholders
          </li>
        </ul>
        <p><strong>NICE TO HAVES:</strong></p>
        <ul>
          <li>Prior experience in crypto/finance industry</li>
          <li>Experienced as a technical writer on open source software</li>
          <li>Programming or other past technical experience</li>
        </ul>
        </div>
    </div>`
  },
  {
    id: 'infrastructure-engineer',
    title: 'Lead Trading Infrastructure Engineer',
    place: 'Melbourne, MEL',
    type: 'Engineering',
    desc: `<div class="job__description body">
      
      <div><h4><strong>RESPONSIBILITIES:&nbsp;</strong></h4>
        <ul>
          <li>Design, build, and scale decentralized matching engine, risk system, and limit order book for blockchain
            protocol using CosmosSDK along with its surrounding backend systems
          </li>
          <li>Provide technical guidance and leadership in navigating tradeoffs (architecture, timeline, etc.)</li>
          <li>Ensure best practices within the engineering team through guidance on architecture, code reviews,
            performance, testing, efficiency, and other engineering excellence improvements
          </li>
          <li>Collaborate with engineers, designers, researchers and product managers to turn requirements into products
            and lead the execution end-to-end
          </li>
          <li>Mentor and develop junior engineers</li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li>8+ years experience working as a backend software engineer</li>
          <li>Experience with trading system development at a mid/high frequency firm writing code to communicate with
            exchanges (i.e. order entry and low latency feed handlers)
          </li>
          <li>And/or experience working on exchange infrastructure, dealing with low latency order intake, risk checks,
            and matching
          </li>
          <li>Strong experience in a systems programming language and network programming knowledge</li>
          <li>Deep understanding of distributed systems and software architectures, excellent coding skills, strong CS
            fundamentals, and strong debugging capabilities
          </li>
          <li>Fast learner who can quickly master new technologies</li>
        </ul>
        </div>
    </div>`
  },
  {
    id: 'designer',
    title: 'Senior Product Designer',
    place: 'Melbourne, MEL',
    type: 'Design',
    desc: `<div class="job__description body">
      
      <div><p><strong>RESPONSIBILITIES:</strong></p>
        <ul>
          <li>Own the end-to-end design process, from ideation and prototyping to user testing and final implementation,
            ensuring a seamless and delightful user journey
          </li>
          <li>Collaborate closely with Product Management, Engineering, and other teams to design best-in-class
            experiences for our users
          </li>
          <li>Design for our web, iOS, and Android platforms</li>
          <li>Contribute to our our design system and brand identity</li>
          <li>Ensure the products we ship are consistent with our design and branding standards</li>
          <li>Perform user research and use data to develop the right product strategy and solutions that delight our
            customers
          </li>
          <li>Design marketing materials and partner with external contact design resources</li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li>4+ years of UX, UI, or product design experience</li>
          <li>Strong product sensibility</li>
          <li>Experience developing wireframes and prototypes</li>
          <li>Proficiency with Figma&nbsp;</li>
          <li>Proven experience building excellent consumer-facing mobile and web products</li>
          <li>A strong portfolio that demonstrates excellence and passion for product design</li>
          <li>You are an open-minded and accountable individual, who respects collaboration&nbsp;</li>
          <li>Excellent communication and organization skills</li>
          <li>Bachelor&apos;s degree in a related design major or equivalent work experience</li>
        </ul>
        <p><strong>NICE TO HAVE:</strong></p>
        <ul>
          <li>Interest in Crypto and or Blockchain technologies</li>
          <li>Interest in FinTech</li>
          <li>Interest in trading</li>
        </ul>
        </div>
    </div>`
  },
  {
    id: 'backend',
    title: 'Senior Software Engineer - Backend',
    place: 'Melbourne, MEL',
    type: 'Engineering',
    desc: `<div class="job__description body">
      
      <div><p><strong>RESPONSIBILITIES:</strong></p>
        <ul>
          <li style={{fontWeight: 400}}>Write low latency financial software that will process billions of dollars a day
            worth of trading volume, including order book matching engines, trading engines, and REST &amp; Websocket
            API endpoints
          </li>
          <li>Develop open source blockchain-based code that powers the Bitstaker protocol</li>
          <li style={{fontWeight: 400}}>Build high performance REST &amp; WebSocket APIs to power our frontend app as
            well as programmatic traders
          </li>
          <li style={{fontWeight: 400}}>Write open source TypeScript &amp; Python clients that allow users to interact
            directly with our on-chain smart contracts
          </li>
          <li>Work together with multi-functional teams to develop the best overall product</li>
          <li>Architect performant and reliable backend systems</li>
          <li>Mentor and develop junior engineers</li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li>5+ years experience working as a full-time developer</li>
          <li>You write secure, efficient, high quality code and have a good understanding of CS fundamentals</li>
          <li>You have experience with or are open to learning JavaScript, TypeScript, Node.js, PostgreSQL, Redis,
            Kafka, Express, WebSockets, Docker
          </li>
          <li>You are a fast learner who can quickly master new technologies</li>
        </ul>
        <p><strong>NICE TO HAVES:</strong></p>
        <ul>
          <li>You&apos;ve worked on high quality products in the finance or security space</li>
          <li>Interest in fullstack development. Experience building Web Services, SQL, CSS, React, Redux</li>
          <li>You have an strong interest in decentralized finance</li>
          <li>You have experience building blockchain applications / smart contracts</li>
          <li>CS Degree or equivalent experience</li>
        </ul>
        </div>
    </div>`
  },
  {
    id: 'frontend',
    title: 'Software Engineer - Frontend',
    place: 'Melbourne, MEL',
    type: 'Engineering',
    desc: `<div class="job__description body">
      
      <div><p><strong>RESPONSIBILITIES:</strong></p>
        <ul>
          <li style={{fontWeight: 400}}>Write low latency financial software that will process billions of dollars a day
            worth of trading volume, including order book matching engines, trading engines, and REST &amp; Websocket
            API endpoints
          </li>
          <li>Develop open source blockchain-based code that powers the Bitstaker protocol</li>
          <li style={{fontWeight: 400}}>Build high performance REST &amp; WebSocket APIs to power our frontend app as
            well as programmatic traders
          </li>
          <li style={{fontWeight: 400}}>Write open source TypeScript &amp; Python clients that allow users to interact
            directly with our on-chain smart contracts
          </li>
          <li>Work together with multi-functional teams to develop the best overall product</li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li>At least 2+ years experience working as a full-time developer (for new graduates, we require internship
            experience from a top tech company)
          </li>
          <li>You have a depth of technical experience and have demonstrated excellence through previous work</li>
          <li>You write secure, efficient, high quality code</li>
          <li>You are a fast learner who can quickly master new technologies</li>
          <li>Requires a Bachelor’s degree in Computer Science or a related field and 3 years of experience or
            alternatively, a Master’s<strong data-stringify-type="bold">&nbsp;</strong>degree in same and 1 year of
            experience
          </li>
        </ul>
        <p><strong>NICE TO HAVES:</strong></p>
        <ul>
          <li>You&apos;ve worked on high-quality products in the finance or security space</li>
          <li>You have experience with JavaScript, Node.js, and/or Postgres</li>
          <li>You have experience building blockchain applications / smart contracts</li>
        </ul>
      
    </div>`
  },
  {
    id: 'software-engineer',
    title: 'Staff Software Engineer',
    place: 'Melbourne, MEL',
    type: 'Engineering',
    desc: `<div class="job__description body">
      
      <div><p><strong>RESPONSIBILITIES:</strong></p>
        <ul>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Write low latency financial software that will process billions of dollars a day worth of trading volume, including order book matching engines, trading engines, and REST &amp; Websocket API endpoints.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Develop open source blockchain-based code that powers the Bitstaker protocol.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Build high performance REST &amp; WebSocket APIs to power our frontend app as well as programmatic traders.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Architect performant and reliable backend systems.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Work together with multi-functional teams to develop the best overall product.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Manage individual projects priorities, deadlines and deliverables with your technical expertise.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Mentor and develop other team members on design techniques, and coding standards.</span>
          </li>
        </ul>
        <p><strong>REQUIREMENTS:</strong></p>
        <ul>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>5+ years experience working as a full-time developer.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You write secure, efficient, high quality code and have a strong understanding of CS fundamentals.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You have experience with or are open to learning JavaScript, TypeScript, Go, Node.js, PostgreSQL, Redis, Kafka, Express, WebSockets, Docker, AWS, Terraform.</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You are a fast learner who can quickly master new technologies.</span>
          </li>
        </ul>
        <p><strong>NICE TO HAVES:</strong></p>
        <ul>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You&apos;ve worked on high quality products in the finance or security space</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You have experience building &amp; operating complex, highly reliable distributed systems involving microservices and APIs</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>An interest in full-stack development. Experience building and architecting backend Web Services, as well as Web or Mobile applications</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You have a strong interest in decentralized finance</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You have experience building blockchain applications, or smart contracts</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>You have experience architecting and developing solutions to ambiguous problems with significant impact</span>
          </li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>CS Degree or equivalent experience</span></li>
        </ul>
       </div>
    </div>`
  },
]

