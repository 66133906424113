export const routes = {
  index: "/",

  farms: "/farms",
  trademark: "/trademark",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  privacy: "/privacy",
  cookies: "/cookies",
  referral: "/referral",
  audit: "/audit",
  pool: "/pool",
  page: "/page",
  swap: "/swap",
  news: "/news",
  swapInfo: "/analytics",
  token: "/token",
  poolInfo: "/pool-info",
  docs: "/litepaper",
  stake: "/stake",
  earn: "/earn",
  blog: "/blog",
  careers: "/missions",
  roadmap: "/roadmap",
  bugBountyTerms: "/bug-bounty-terms",
  tokensData: "/tokens-data",
  priceGraf: "/price-graph",
  information: "/bug-bounty",
  protocol: "/protocol-stats",
  supportedToken: "/supported-tokens",
  jobs: "/careers",
  employReferralProgram: "/employee-referral-program",
  promotion: "/promotion-and-marketing-services-agreement",
}
