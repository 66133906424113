import img1 from "assets/icons/chains/5.svg"

export const poolDataFantom = [
  {
    icon: img1,
    name: "Fantom Opera",
    token: "FTM",
    network: "Fantom Opera",
    id: "fantom",
    addresses: [
      "0x3ddcb7117b4c5f609b61e15154a56b359ab8a7fe",
      "0xb458bfc855ab504a8a327720fcef98886065529b",
      "0x7f9056018c742bf25fbe6f4b553581af54f308fc",
      "0xb8e6d31e7b212b2b7250ee9c26c56cebbfbe6b23",
    ],
    quantity: ["25000-50000", "30000-75000", "75000-100000", "100000-300000"],
    percents: ["1.6", "4", "10", "17"],
    days: ["14", "30", "60", "90"],
  },
]
