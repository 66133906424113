import img1 from "assets/icons/chains/10.svg"

const tokensText = [
  {
    token: "MANTA",
    tokenDesc: "Manta Network is the modular ecosystem for web3. Users can build and deploy any Solidity-based decentralized applications on Manta and leverage its technology stack to deliver faster transaction speeds than an L1 and lower gas cost than an L2.",
    site: "https://manta.network/"
  },
];

export const poolDataSeparatedManta = [
  {
    icon: img1,
    name: "Manta",
    token: "MANTA",
    network: "Manta",
    id: "manta-network",
    address: "0x51619bc93ab9252fb17cce44a4e4971d42dfca34",
    addAddress: null,
    quantity: "7000-14000",
    index: 1,
    percent: "1.5",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Manta",
    token: "MANTA",
    network: "Manta",
    id: "manta-network",
    address: "0x38060c598af2de576b950faa846e83e2fa7b19e9",
    addAddress: null,
    quantity: "14000-42000",
    percent: "3.8",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Manta",
    token: "MANTA",
    network: "Manta",
    id: "manta-network",
    address: "0xbf67108c1d2ec211e38976b11f4952508181742f",
    addAddress: null,
    quantity: "28000-70000",
    percent: "9.4",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Manta",
    token: "MANTA",
    network: "Manta",
    id: "manta-network",
    address: "0x06018c2e3089fa1abb23f253c56b9cbc86096e4d",
    addAddress: null,
    quantity: "100000-180000",
    percent: "16",
    day: "90",
    text: tokensText[0],
    index: 4
  },
]
