import React from "react"
import {Footer, Header} from "components"

import imgMainBgSrc from "assets/images/main-bg.webp"

export const Promotion = (): JSX.Element => {

  return (
    <div className="terms" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<gecko-coin-price-marquee-widget
            coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,Bitstaker-chain"
            currency="usd"
            dark-mode="true"
            locale="en"/>)
        }
      </div>
      <article className="terms-content"><h1
        className="terms-content-title ">Master Agreement for Promotion and Marketing Services</h1>
        <div className="LegalDocumentContent__Legal-sc-1nv2gkj-0 OyWDD"><p>This Master Agreement for Promotion and
          Marketing
          Services (the &quot;<b><i>Agreement</i></b>&quot;), as revised or amended from time to time is entered into between you
          (the &quot;<b><i>Service Provider</i></b>&quot; or “<b><i>you</i></b>”) and Bitstaker Trading Inc., a Delaware corporation (&quot;<b><i>Client</i></b>&quot;,
          “<b><i>Bitstaker</i></b>”, or the “<b><i>Company</i></b>”, together with Service Provider, the
          “<b><i>parties</i></b>”), and is acknowledged and agreed to by the parties at the first to occur of the full
          execution of this Agreement or its Statement of Work, or your receipt and acceptance of any payment in
          exchange for performance of the Services (defined below) (“<b><i>Effective Date</i></b>”).&nbsp;&nbsp;</p>
          <p>This Agreement incorporates and supplements Bitstaker’s <a href="/terms">Terms of Use</a> (the “<b><i>Terms</i></b>”),
            and <a href="/privacy">Privacy Policy</a> (the “<b><i>Privacy Policy</i></b>”), and <a
              href="/trademark">Trademark</a> (“<b><i>Competition
              Terms</i></b>”), in each case, to which Service Provider agrees that Service Provider is bound and is a
            party, and the terms of which apply to this Agreement.&nbsp;</p><p>In consideration of the mutual covenants,
            terms, and conditions set forth herein, and for other good and valuable consideration, the receipt and
            sufficiency of which are hereby acknowledged, the parties agree as follows:</p>
          <ul>
            <li><p><code>1. </code><u>Service Provider Services and Responsibilities</u>. &nbsp;</p>
              <ul>
                <li><p><code>1.1 </code><u>Services</u>. Service Provider shall provide to Client the promotion and
                  marketing services described generally in Exhibit A (the &quot;<b><i>Services</i></b>&quot;) and set out more
                  specifically in one or more statements of work to be issued by Client and accepted by Service Provider
                  (each, a &quot;<b><i>Statement of Work</i></b>&quot; or &quot;<b><i>SOW</i></b>&quot;). In the event of any inconsistency
                  between an SOW and this Agreement, the terms of this Agreement shall control.</p>
                  <ul>
                    <li><p><code>(a) </code>The parties shall set forth each agreed task in a Statement of Work in the
                      form attached hereto as Exhibit B. Statements of Work shall be deemed accepted and incorporated
                      into this Agreement upon the acceptance of this Agreement, shown by any performance by the Service
                      Provider of the Services.&nbsp;</p></li>
                    <li><p><code>(b) </code>Service Provider shall use best efforts to provide the Services: (a) in
                      accordance with the terms and subject to the conditions set forth in the respective Statement of
                      Work and this Agreement; (b) using personnel of required skill, experience, and qualifications;
                      (c) in a workmanlike and professional manner; and (d) in accordance with the highest professional
                      standards in the promotion and marketing field; and (d) according to any applicable laws and
                      regulations.&nbsp;</p></li>
                    <li><p><code>(c) </code>The Services shall not be deemed performed until finished and presented to
                      Client, and submitted in writing to Client via invoice, whereupon Client shall review and accept
                      or reject the Services in Client’s sole discretion, based upon a reasonable interpretation of the
                      terms of the Agreement and the description of the Services; provided that if Client does not
                      accept the Services then such Services shall be deemed unaccepted.&nbsp;</p></li>
                  </ul>
                </li>
                <li><p><code>1.2 </code><u>Service Provider and Personnel</u>. Subject to the prior written approval of
                  Client, Service Provider shall:</p>
                  <ul>
                    <li><p><code>(a) </code>comply with all applicable laws and any collective bargaining agreements
                      affecting any Service Provider Personnel.</p></li>
                    <li><p><code>(b) </code>be responsible for the payment of all compensation owed to the Service
                      Provider Personnel, including, if applicable, the payment and withholding of social security and
                      other payroll taxes, withholding of income taxes, unemployment insurance, workers&apos; compensation
                      insurance payments, and disability benefits.</p></li>
                    <li><p><code>(c) </code>obtain Client&apos;s prior written approval prior to entering into agreements
                      with or otherwise engaging any person who is not a Service Provider employee, including any
                      independent consultants, contractors, subcontractors, or affiliates of Service Provider (each such
                      approved third party, a &quot;<b><i>Permitted Subcontractor</i></b>&quot;), to provide any Services to
                      Client.</p></li>
                    <li><p><code>(d) </code>All persons employed by Service Provider in connection with the Services
                      shall either be employees of Service Provider or Permitted Subcontractors retained by Service
                      Provider and Service Provider shall be solely responsible for complying with all laws and
                      collective bargaining agreements affecting such persons.</p></li>
                  </ul>
                </li>
                <li><p><code>1.3 </code><u>Influencer Campaign</u>. In the event that Service Provider engages, or acts
                  as, a blogger, personality, celebrity, or other individual to post on social media platforms
                  (&quot;<b><i>Influencer</i></b>&quot;) to promote Client&apos;s products and services, Service Provider shall manage
                  the Influencer campaign as follows:</p>
                  <ul>
                    <li><p><code>(a) </code>Service Provider shall:&nbsp;</p>
                      <ul>
                        <li><p><code>(i) </code>disclose their material connection to Client clearly and conspicuously
                          in their postings or statements;</p></li>
                        <li><p><code>(ii) </code>ensure that statements reflect their honest opinions, beliefs, and
                          experiences;</p></li>
                        <li><p><code>(iii) </code>not make any false or misleading statements about Client&apos;s products or
                          services;</p></li>
                        <li><p><code>(iv) </code>review and abide by FTC&apos;s guidance on endorsements; and</p></li>
                        <li><p><code>(v) </code>review and abide by Client&apos;s Social Media Endorsement Policy, attached
                          hereto as Exhibit C;&nbsp;</p></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><p><code>1.4 </code><u>Compliance with Laws</u>. Service Provider shall at all times comply with all
                  applicable federal, state, and local laws, ordinances, regulations, and orders that are applicable to
                  this Agreement and performance hereunder.&nbsp;</p></li>
                <li><p><code>1.5 </code><u>Compliance with Industry Standards</u>. Service Provider shall comply with
                  promotion and marketing industry standards and self-regulatory guidelines and best practices in
                  providing the Services.</p></li>
                <li><p><code>1.6 </code><u>Campaigns with Payment by Service Provider</u>.&nbsp; Service Provider shall
                  clearly disclose in connection with any marketing or promotional campaign that (i) such campaign is
                  run and operated by Service Provider and not Client, (ii) that Service Provider is solely responsible
                  for making payment related to such campaign, and (iii) that Service Provider is providing the Services
                  as a Service Provider (and not as a partnership or joint venture).</p></li>
                <li><p><code>1.7 </code>Nothing in this Agreement will be construed to create any partnership, joint
                  venture, franchise, sales representation, or employment relationship between Service Provider and
                  Client.&nbsp; Service Provider is not permitted to speak for Client or any of its affiliates or bind
                  Client in any way and has no authority to do so. Service Provider must not make any claims,
                  representations, or warranties in connection with Client</p></li>
                <li><p><code>1.8 </code>If Service Provider is headquartered or located or has any offices in, or is a
                  resident of, the United States, then, Service Provider will include in any public postings or
                  published statements the following: “Bitstaker perpetual contracts are unavailable in the United States.”
                  If Service Provider is not headquartered or located and does not have offices in, and is not a
                  resident of, the United States, then, with any post or published statement on a medium targeted at the
                  United States, Service Provider will post therewith the following: “Bitstaker perpetual contracts are
                  unavailable in the United States.”</p></li>
              </ul>
            </li>
            <li><p><code>2. </code><u>Intellectual Property</u>. &nbsp;</p>
              <ul>
                <li><p><code>2.1 </code>Client shall provide, as needed, copies of or access to Client&apos;s information,
                  documents, samples, products and services, or other materials (collectively, &quot;<b><i>Client
                    Materials</i></b>&quot;) as Service Provider may require in order to carry out the Services in a timely
                  manner and ensure that they are complete and accurate in all material respects.&nbsp; Client and its
                  licensors are, and shall remain, the sole and exclusive owner of all right, title, and interest in and
                  to all Client Materials, including all copyrights, trademarks, service marks, trade dress, trade
                  names, trade secrets, patents, mask works, and other intellectual and industrial property rights
                  (collectively &quot;<b><i>Intellectual Property Rights</i></b>&quot;) therein. Service Provider shall have no
                  right or license to use any Client Materials other than during the Term to the extent necessary to
                  provide the Services to Client, and all other rights in and to the Client Materials are expressly
                  reserved by Client.</p></li>
                <li><p><code>2.2 </code>Client owns all worldwide right, title, and interest in and to all written,
                  graphic, coded, audio, and visual materials and other work product (whether finished or unfinished)
                  that are delivered to or developed for Client’s use or benefit under this Agreement, or provided to
                  Client under this Agreement, or data or information sent to or shared with Client pursuant to any
                  Services described in this Agreement, including all Intellectual Property Rights therein, together
                  with all of the goodwill associated therewith (collectively, the &quot;<b><i>deliverables</i></b>&quot;). The
                  deliverables shall include, without limitation all copy, blogs, storyboards, concepts, ideas,
                  inventions, discoveries, domain names, logos, taglines, slogans, website design, style, content,
                  structure and look and feel, internet portals, videos, research, studies, reports, presentations and
                  proposals, artwork, videos, music, lyrics, photographs, graphic materials, audiovisual works, and
                  telephone numbers for use by Client&apos;s consumers.</p></li>
                <li><p><code>2.3 </code>Any deliverables protectable under United States copyright law shall be owned by
                  Client as &quot;works made for hire&quot; as defined in Section 101 of the United States Copyright Act.&nbsp; To
                  the extent that any or all of such deliverables are not deemed a work made for hire, Service Provider
                  assigns to Client all right, title, and interest in and to the worldwide copyrights in such
                  deliverables. With respect to all other Intellectual Property Rights in the deliverables, Service
                  Provider irrevocably assigns to Client all worldwide right, title, and interest in and to all
                  Intellectual Property Rights in such deliverables.&nbsp;</p></li>
                <li><p><code>2.4 </code>Upon the request of Client, Service Provider shall promptly take such further
                  actions, including execution and delivery of all appropriate instruments of conveyance, as may be
                  necessary to assist Client to prosecute, register, perfect, or record its rights in or to any
                  deliverables and all Intellectual Property Rights therein.&nbsp;</p></li>
                <li><p><code>2.5 </code>Service Provider acknowledges and agrees that:</p>
                  <ul>
                    <li><p><code>(i) </code>all deliverables that may qualify as &quot;works made for hire&quot; will be deemed
                      &quot;works made for hire&quot; for Client and, to the extent that any of the deliverables does not
                      constitute a &quot;work made for hire,&quot; or is protected under other Intellectual Property Rights, to
                      irrevocably assign, to Client, in each case without additional consideration, all worldwide right,
                      title, and interest in and all the Intellectual Property Rights in such deliverables.</p></li>
                    <li><p><code>(ii) </code>Service Provider irrevocably waives, to the extent permitted by applicable
                      law, any and all claims such Service Provider may now or hereafter have in any jurisdiction to
                      so-called &quot;moral rights&quot; concerning the deliverables.</p></li>
                  </ul>
                </li>
                <li><p><code>2.6 </code>Notwithstanding anything herein to the contrary, Client&apos;s ownership of the
                  deliverables shall be subject to (i) the rights of third parties whose materials or services are
                  contained in the deliverables with Client&apos;s prior knowledge and written approval (for example, stock
                  footage, photos, music, and software) and used under a license or other permission granted to Service
                  Provider or Client (&quot;<b><i>Third-Party Materials</i></b>&quot;), or (ii) all materials owned by Service
                  Provider prior to, or independent from, the performance of Services under this Agreement, and all
                  methodologies, software, applications, processes or procedures used, created, or developed by Service
                  Provider in the general conduct of its business, excluding those developed specifically for Client or
                  at Client&apos;s request or funded by Client (collectively, &quot;<b><i>Pre-Existing Materials</i></b>&quot;).
                  [Service Provider shall identify all Pre-Existing Materials in the applicable SOW.] Service Provider
                  hereby grants Client a royalty-free, perpetual, worldwide license to any Service Provider Materials to
                  the extent incorporated in, combined with, or otherwise necessary for the use of the deliverables for
                  all purposes. Service Provider shall disclose in writing to Client all usage limitations on
                  Third-Party Materials prior to their use in or launch of any deliverables.</p></li>
                <li><p><code>2.7 </code>Subject to and in accordance with the terms and conditions of this Agreement,
                  Client grants Service Provider a limited, non-transferable, non-sublicensable non-exclusive license
                  during the Term to use, solely in connection with its performance of the Services, Client&apos;s
                  Intellectual Property Rights required to perform the Services.&nbsp;</p></li>
                <li><p><code>2.8 </code>Client grants no other right or license to any of its Intellectual Property
                  Rights to Service Provider by implication, estoppel, or otherwise. Service Provider acknowledges that
                  Client owns all right, title, and interest in, to, and under all its Intellectual Property Rights and
                  that Service Provider shall not acquire any proprietary rights therein. Any use by Service Provider or
                  any affiliate, employee, officer, director, partner, shareholder, agent, attorney, third-party
                  advisor, successor, or permitted assign (collectively, &quot;<b><i>Representatives</i></b>&quot;) of Service
                  Provider or Permitted Subcontractors of any of Client&apos;s Intellectual Property Rights and all goodwill
                  associated therewith shall inure to the benefit of Client.&nbsp;</p></li>
              </ul>
            </li>
            <li><p><code>3. </code><u>Fees and Expenses; Payment Obligations</u>. &nbsp;</p>
              <ul>
                <li><p><code>3.1 </code><u>Fees and Expenses</u>. </p>
                  <ul>
                    <li><p><code>(a) </code>In consideration of the provision of the Services and the rights granted to
                      Client under this Agreement, Client shall pay Service Provider the fees agreed to in each SOW (the
                      &quot;<b><i>Fees</i></b>&quot;).&nbsp;</p></li>
                    <li><p><code>(b) </code>Client will not pay any travel or out-of-pocket expenses incurred by Service
                      Provider in connection with the performance of the Services, unless provided otherwise in any
                      applicable SOW.&nbsp;</p></li>
                    <li><p><code>(c) </code>Payment to Service Provider of the Fees shall constitute payment in full for
                      the performance of the Services, and Client shall not be responsible for paying any other fees,
                      costs, or expenses.</p></li>
                  </ul>
                </li>
                <li><p><code>3.2 </code><u>Taxes</u>. All fees payable by Client under this Agreement are exclusive of
                  all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed
                  by any governmental authority on such amounts. Service Provider shall be responsible for any taxes
                  imposed on, or with respect to, Service Provider&apos;s income, revenues, gross receipts, personnel, or
                  real or personal property, or other assets.&nbsp;</p></li>
                <li><p><code>3.3 </code><u>Invoice Disputes</u>. Client shall notify Service Provider in writing of any
                  dispute regarding payment.&nbsp; The parties shall seek to resolve all such disputes expeditiously and
                  in good faith.&nbsp;</p></li>
              </ul>
            </li>
            <li><p><code>4. </code><u>Representations, Warranties, and Certain Covenants</u>.</p>
              <ul>
                <li><p><code>4.1 </code><u>Service Provider Representations, Warranties, and Covenants</u>. Service
                  Provider represents, warrants, and covenants to Client that:</p>
                  <ul>
                    <li><p><code>(a) </code>it has or shall obtain and shall maintain in full force and effect during
                      the Term, at its own expense, all certifications, credentials, authorizations, licenses, and
                      permits necessary to the exercise of its rights and the performance of its obligations under this
                      Agreement.</p></li>
                    <li><p><code>(b) </code>it shall comply in all material respects with, and ensure that all Service
                      Provider Personnel and Permitted Subcontractors comply with, all specifications, rules,
                      regulations, and policies of Client that are communicated to Service Provider in writing,
                      including without limitation the following Client policies:</p>
                      <ul>
                        <li><p><code>(i) </code>The Policies (see Preamble above);&nbsp;</p></li>
                        <li><p><code>(ii) </code>All applicable laws; and&nbsp;</p></li>
                        <li><p><code>(iii) </code>Social Media and Promotion Endorsement Policy</p></li>
                      </ul>
                    </li>
                    <li><p><code>(c) </code>None of the Services, or any applicable deliverables, or client&apos;s use
                      thereof infringe or violate or will infringe or violate the publicity rights, privacy rights or
                      any other Intellectual Property Rights of any third party.&nbsp;</p></li>
                    <li><p><code>(d) </code>If Service Provider is an entity, then Service Provider (a) is duly
                      organized and validly existing in good standing under the laws of the jurisdiction of its
                      formation or other jurisdiction in which it is qualified to do business; (b) has the power and
                      authority to execute, deliver and carry out the terms and provisions of this Addendum and
                      consummate the transactions contemplated hereby; (c) has taken all necessary action to authorize
                      the execution, delivery and performance of this Agreement and the consummation of the transactions
                      contemplated hereby; and (d) has duly executed and delivered this Agreement. If Service Provider
                      is an individual, then Service Provider (i) has the capacity to execute, deliver and carry out the
                      terms and provisions of this Agreement and consummate the transactions contemplated hereby and
                      (ii) has duly executed and delivered this Agreement. This Agreement constitutes Service Provider’s
                      legal, valid and binding obligation, enforceable against Service Provider in accordance with its
                      terms.</p></li>
                    <li><p><code>(e) </code>Service Provider will not make any false, deceptive, misleading, derogatory
                      or defamatory statements with respect to the Client, its products or services, or its employees,
                      Users, the Site or the Protocol.</p></li>
                    <li><p><code>(f) </code>Service Provider will not use any ethnic slurs, personal insults, obscenity,
                      or offensive statements or comments in connection with the Services, Client, or its employees,
                      Users, the Site or the Protocol.</p></li>
                    <li><p><code>(g) </code>Service Provider will not use content or material that (a) violates or
                      infringes in any way upon the statutory, common law, or proprietary rights of others, including
                      copyrights, trademark rights, patents, or any other third-party intellectual property, contract,
                      privacy or publicity rights; or (b) violates any local, state, or national law, rule or
                      regulation.&nbsp;</p></li>
                    <li><p><code>(h) </code>Service Provider will not create websites or advertisements that copy,
                      imitate, or resemble the Site or the look and feel of the Site. Service Provider will not copy the
                      Site or any portions thereof, including any of Client’s trademarks (or licensed trademarks) or
                      other intellectual property, or display them on Service Provider’s own site or subdomain or use
                      them in any way without Bitstaker’s prior express written consent.</p></li>
                    <li><p><code>(i) </code>Service Provider, and to its knowledge, its directors, officers, employees,
                      agents, Service Providers or representatives, is not, and are not, owned or controlled by a person
                      that (a) is the subject of any sanctions administered or enforced by the U.S. Department of
                      Treasury’s Office of Foreign Assets Control (“<b><i>Sanctions</i></b>”); (b) is located, organized
                      or resident in a country or territory that is the subject of Sanctions; and (c) will, directly or
                      indirectly, use the proceeds received under this Addendum or otherwise make available such
                      proceeds to any subsidiary, joint venture partner or other person (i) to fund or facilitate any
                      activities or business of or with any person or in any country or territory that, at the time of
                      such funding or facilitation, is the subject of Sanctions; or (ii) in any other manner that will
                      result in a violation of Sanctions by any person.</p></li>
                    <li><p><code>(j) </code>Service Provider must not (a) use cookie stuffing techniques that set the
                      tracking cookie without the potential participant’s actively pursuing the products and services;
                      (b) bid on or use Bitstaker’s trademarks (or licensed trademarks) or misspelled keywords for the
                      purpose of pay-per-click on internet search engines; (c)engage in domain forwarding (<i>i.e.</i>,
                      purchasing a domain and setting it to forward directly to the Client’s products); (d) engage in
                      any marketing activity that may harm the reputation or credibility of Bitstaker or the Bitstaker protocol,
                      including using low-quality marketing materials, or advertising on any website that promotes
                      violence, discrimination based on race, sex, religion, nationality, disability, sexual orientation
                      or age, or any illegal activities, or violates the intellectual property or other rights of a
                      third party; (e) engage in spam, electronic messaging, or any marketing or promotional activities
                      that violate applicable laws, rules, or regulations; or (f) use malware or spyware to market or
                      promote Client and its products and services.</p></li>
                  </ul>
                </li>
                <li><p><code>4.2 </code><u>Client Representations, Warranties, and Covenants</u>. Client represents,
                  warrants, and covenants to Service Provider that the Client&apos;s Intellectual Property and Client
                  Materials provided to Service Provider for use as permitted in this Agreement does not infringe or
                  violate or will infringe or violate the publicity and privacy rights or any other Intellectual
                  Property Rights of any third party.&nbsp;</p></li>
                <li><p><code>4.3 </code><u>NO OTHER REPRESENTATIONS OR WARRANTIES; NON-RELIANCE</u>. EXCEPT FOR THE
                  EXPRESS REPRESENTATIONS AND WARRANTIES CONTAINED IN THIS AGREEMENT, (A) NEITHER PARTY TO THIS
                  AGREEMENT, NOR ANY OTHER PERSON ON SUCH PARTY&apos;S BEHALF, HAS MADE OR MAKES ANY EXPRESS OR IMPLIED
                  REPRESENTATION OR WARRANTY, EITHER ORAL OR WRITTEN, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE
                  OF PERFORMANCE, USAGE, TRADE, OR OTHERWISE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, AND (B) EACH PARTY
                  ACKNOWLEDGES THAT IT HAS NOT RELIED UPON ANY REPRESENTATION OR WARRANTY MADE BY THE OTHER PARTY, OR
                  ANY OTHER PERSON ON SUCH PARTY&apos;S BEHALF, EXCEPT AS SPECIFICALLY PROVIDED IN SECTION 5 OF THIS
                  AGREEMENT.</p></li>
              </ul>
            </li>
            <li><p><code>5. </code><u>Indemnification</u>.</p>
              <ul>
                <li><p><code>5.1 </code><u>Service Provider Indemnification Obligations</u>. Service Provider shall
                  defend, indemnify, and hold harmless Client, and its officers, directors, employees, agents,
                  affiliates, successors, and permitted assigns (collectively, &quot;<b><i>Client Indemnified Party</i></b>&quot;),
                  from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments,
                  settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including
                  reasonable attorney fees, the costs of enforcing any right to indemnification under this Agreement,
                  and the cost of pursuing any insurance providers (&quot;<b><i>Losses</i></b>&quot;) arising out of or resulting
                  from any third-party claim or direct claim alleging:</p>
                  <ul>
                    <li><p><code>(a) </code>breach by Service Provider or any Service Provider Personnel of any
                      representation, warranty, covenant, or other obligations set forth in this Agreement;&nbsp;</p>
                    </li>
                    <li><p><code>(b) </code>negligence or more culpable act or omission of Service Provider or any
                      Service Provider Personnel (including any recklessness or willful misconduct) in connection with
                      the performance of its obligations under this Agreement; and/or</p></li>
                    <li><p><code>(c) </code>that any of the Services or deliverables or Client&apos;s receipt or use thereof
                      infringes or violates the publicity or privacy rights or any other Intellectual Property Rights of
                      a third party arising under the laws of the United States.</p></li>
                  </ul>
                </li>
                <li><p><code>5.2 </code><u>Client Indemnification Obligations</u>. Client shall defend, indemnify, and
                  hold harmless Service Provider, and its officers, directors, employees, agents, affiliates,
                  successors, and permitted assigns (collectively, &quot;<b><i>Service Provider Indemnified Party</i></b>&quot;),
                  from and against any and all Losses arising out of or resulting from any third-party claim or direct
                  claim alleging:&nbsp;</p>
                  <ul>
                    <li><p><code>(a) </code>breach by Client of any material representation, warranty, covenant, or
                      other obligations set forth in this Agreement;&nbsp;</p></li>
                    <li><p><code>(b) </code>gross negligence or more culpable act or omission of Client (including any
                      recklessness or willful misconduct) in connection with the performance of its obligations under
                      this Agreement; and&nbsp;</p></li>
                    <li><p><code>() </code>that any Client Materials or Client&apos;s Intellectual Property Rights that
                      Service Provider uses to perform the Services or incorporate into the deliverables in accordance
                      with the terms of this Agreement infringes or violates the publicity or privacy rights or any
                      other Intellectual Property Rights of a third party.</p></li>
                  </ul>
                </li>
                <li><p><code>5.3 </code><u>Indemnification Procedures</u>. A party seeking indemnification under this 5
                  (the &quot;<b><i>Indemnified Party</i></b>&quot;) shall give the party from whom indemnification is sought (the
                  &quot;<b><i>Indemnifying Party</i></b>&quot;): (a) prompt Notice of the relevant claim; provided, however, that
                  failure to provide such Notice shall not relieve the Indemnifying Party from its liability or
                  obligation hereunder except to the extent of any material prejudice directly resulting from such
                  failure; and (b) reasonable cooperation in the defense of such claim. The Indemnified Party shall have
                  the right to control the defense and settlement of any such claim; provided, and the Indemnifying
                  Party shall not, without the prior written approval of the Indemnified Party, settle or dispose of any
                  claims in a manner that affects the Indemnified Party&apos;s rights or interest. The Indemnified Party
                  shall have the right to participate in the defense at its own expense.</p></li>
              </ul>
            </li>
            <li><p><code>6. </code><u>Limitation of Liability</u>. &nbsp;</p>
              <ul>
                <li><p><code>6.1 </code><u>NO LIABILITY FOR CONSEQUENTIAL OR INDIRECT DAMAGES.</u> EXCEPT WITH RESPECT
                  TO THE PARTIES&apos; LIABILITY FOR INDEMNIFICATION, LIABILITY FOR BREACH OF CONFIDENTIALITY, OR LIABILITY
                  FOR INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY, IN NO EVENT WILL EITHER PARTY BE LIABLE
                  TO THE OTHER FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES
                  WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF USE, REVENUE OR PROFIT, BUSINESS INTERRUPTION, AND LOSS OF
                  INFORMATION), WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
                  REGARDLESS OF WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF
                  THE POSSIBILITY OF SUCH DAMAGES.&nbsp;</p></li>
                <li><p><code>6.2 </code><u>MAXIMUM LIABILITY</u>. EXCEPT WITH RESPECT TO THE PARTIES&apos; LIABILITY FOR
                  INDEMNIFICATION, LIABILITY FOR BREACH OF CONFIDENTIALITY, OR LIABILITY FOR INFRINGEMENT OR
                  MISAPPROPRIATION OF INTELLECTUAL PROPERTY OR BREACH OF APPLICABLE LAW, EACH PARTY&apos;S AGGREGATE
                  LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF
                  CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL NOT EXCEED 2X THE TOTAL OF THE AMOUNTS PAID
                  AND AMOUNTS ACCRUED BUT NOT YET PAID TO SERVICE PROVIDER PURSUANT TO THIS AGREEMENT IN THE 12 MONTH
                  PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.&nbsp;</p></li>
              </ul>
            </li>
            <li><p><code>7. </code><u>Insurance</u>. During the Term and for 2 years thereafter, Service Provider shall,
              at its own expense, maintain and carry in full force and effect, subject to appropriate levels of
              self-insurance, at least the following types and amounts of insurance coverage:</p>
              <ul>
                <li><p><code>7.1 </code>Commercial general liability with limits no less than $500,000 for each
                  occurrence and $1 million in the aggregate, including but not limited to advertising liability and
                  contractual liability coverage insuring activities relating to the Services contemplated in this
                  Agreement; and</p></li>
                <li><p><code>7.2 </code>Umbrella liability for the coverage referenced in Section 7.1 and Section 7.2,
                  with limits no less than $1 million.</p></li>
              </ul>
            </li>
            <li><p><code>8. </code><u>Confidentiality</u>. From time to time during the Term, either party (as the
              &quot;<b><i>Disclosing Party</i></b>&quot;) may disclose or make available to the other party (as the &quot;<b><i>Receiving
                Party</i></b>&quot;) information about its business affairs and services, confidential information, and
              materials comprising or relating to Intellectual Property, trade secrets, third-party confidential
              information, and other sensitive or proprietary information, as well as the terms of this Agreement,
              whether orally or in written, electronic and whether or not marked, designated or otherwise identified as
              &quot;confidential&quot; (collectively, &quot;<b><i>Confidential Information</i></b>&quot;). Confidential Information does not
              include information that, at the time of disclosure: (a) is or becomes generally available to and known by
              the public other than as a result of, directly or indirectly, any breach of this 8 by the Receiving Party
              or any of its Representatives; (b) is or becomes available to the Receiving Party on a non-confidential
              basis from a third-party source, provided that such third party is not and was not prohibited from
              disclosing such Confidential Information; (c) was known by or in the possession of the Receiving Party or
              its Representatives prior to being disclosed by or on behalf of the Disclosing Party; (d) was or is
              independently developed by the Receiving Party without reference to or use of, in whole or in part, any of
              the Disclosing Party&apos;s Confidential Information; or (e) is required to be disclosed pursuant to applicable
              law. The Receiving Party shall for the duration of this Agreement, and for 3 years from any termination or
              expiration of this Agreement: (x) protect and safeguard the confidentiality of the Disclosing Party&apos;s
              Confidential Information with at least the same degree of care as the Receiving Party would protect its
              own Confidential Information, but in no event with less than a commercially reasonable degree of care; (y)
              not use the Disclosing Party&apos;s Confidential Information, or permit it to be accessed or used, for any
              purpose other than to exercise its rights or perform its obligations under this Agreement; and (z) not
              disclose any such Confidential Information to any person, except to the Receiving Party&apos;s Representatives
              who need to know the Confidential Information to assist the Receiving Party, or act on its behalf, to
              exercise its rights or perform its obligations under this Agreement. The Receiving Party shall be
              responsible for any breach of this ion 8 caused by any of its Representatives. At any time during or after
              the Term, at the Disclosing Party&apos;s written request, the Receiving Party and its Representatives shall,
              destroy all Confidential Information and copies thereof that it has received under this Agreement.</p>
            </li>
            <li><p><code>9. </code><u>Term; Termination</u>.</p>
              <ul>
                <li><p><code>9.1 </code><u>Term</u>. Unless set forth separately in the SOW, the term of this Agreement
                  and any SOW hereto commences on Effective Date and continues 12 months unless it is earlier terminated
                  in accordance with the terms of this Agreement (the &quot;<b><i>Term</i></b>&quot;).&nbsp; Unless stated
                  otherwise in the SOW, neither this Agreement nor any SOW shall auto-renew at the end of the
                  Term.&nbsp;</p></li>
                <li><p><code>9.2 </code><u>Termination for Cause</u>. &nbsp;</p>
                  <ul>
                    <li><p><code>(a) </code>Either party may terminate this Agreement, effective upon written Notice, to
                      the other party (the &quot;<b><i>Defaulting Party</i></b>&quot;) if the Defaulting Party:&nbsp;</p>
                      <ul>
                        <li><p><code>(i) </code>breaches this Agreement, and such breach is incapable of cure, or with
                          respect to a material breach capable of cure, the Defaulting Party does not cure such breach
                          within 30 days after receipt of written Notice of such breach; or</p></li>
                        <li><p><code>(ii) </code>becomes insolvent or is generally unable to pay its debts as they
                          become due; files or has filed against it, a petition for voluntary or involuntary bankruptcy
                          or otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any
                          domestic or foreign bankruptcy or insolvency law; makes or seeks to make a general assignment
                          for the benefit of its creditors; applies for or has appointed a receiver, trustee, custodian,
                          or similar agent appointed by order of any court of competent jurisdiction to take charge of
                          or sell any material portion of its property or business; or is dissolved or liquidated.&nbsp;
                        </p></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><p><code>9.3 </code><u>Termination without Cause</u>. Client may terminate this Agreement and/or any
                  related SOW, effective immediately, at any time in its sole discretion.&nbsp;&nbsp;</p></li>
                <li><p><code>9.4 </code><u>Effect of Expiration or Termination</u>. &nbsp;</p>
                  <ul>
                    <li><p><code>(a) </code>Upon termination of this Agreement Client shall promptly make payment to
                      Service Provider for any outstanding amounts due and payable for any Services performed in
                      accordance with this Agreement prior to the date of such termination.</p></li>
                    <li><p><code>(b) </code>Upon the expiration or termination of this Agreement for any reason each
                      party shall promptly:</p>
                      <ul>
                        <li><p><code>(i) </code>destroy all documents and tangible materials (and any copies)
                          containing, reflecting, incorporating, or based on the other party&apos;s Confidential Information;
                        </p></li>
                        <li><p><code>(ii) </code>permanently erase all of the other party&apos;s Confidential Information
                          from its computer systems, except for copies that are maintained as archive copies on its
                          disaster recovery or information technology backup systems, which it shall destroy upon the
                          normal expiration of its backup files; and</p></li>
                        <li><p><code>(iii) </code>certify in writing to the other party that it has complied with the
                          requirements of this clause; provided, however, that Client may retain copies of any
                          Confidential Information of Service Provider incorporated in the deliverables or to the extent
                          necessary to allow it to make full use of the Services and any applicable deliverables.</p>
                        </li>
                      </ul>
                    </li>
                    <li><p><code>(c) </code>Upon expiration or termination of this Agreement for any reason Service
                      Provider shall:&nbsp;</p>
                      <ul>
                        <li><p><code>(i) </code>promptly deliver to Client all deliverables (whether complete or
                          incomplete) for which Client has paid and all Client Materials;&nbsp;</p></li>
                        <li><p><code>(ii) </code>provide reasonable cooperation and assistance to Client upon Client&apos;s
                          written request and at Client&apos;s expense in transitioning the Services to an alternate Service
                          Provider; and</p></li>
                        <li><p><code>(iii) </code>on a pro-rata basis, repay any fees and expenses paid in advance for
                          any Services or deliverables that have not been provided.</p></li>
                      </ul>
                    </li>
                    <li><p><code>(d) </code>In no event shall Client be liable for any Service Provider personnel
                      termination costs arising from the expiration or termination of this Agreement.&nbsp;</p></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><p><code>10. </code><u>Miscellaneous</u>.</p>
              <ul>
                <li><p><code>10.1 </code><u>Entire Agreement</u>. This Agreement, including the related schedules
                  attached hereto, constitutes the sole and entire agreement of the parties with respect to the subject
                  matter contained herein and therein and supersedes all prior and contemporaneous understandings,
                  agreements, representations, and warranties, both written and oral, with respect to such subject
                  matter.</p></li>
                <li><p><code>10.2 </code><u>Survival</u>. Subject to the limitations and other provisions of this
                  Agreement, 5, 6, 7, 8, Section 9, and 10 this Agreement, as well as any other provision that, in order
                  to give proper effect to its intent, should survive such expiration or termination, shall survive the
                  expiration or earlier termination of this Agreement for the period specified therein, or if nothing is
                  specified for a period of 24 months after such expiration or termination.&nbsp;</p></li>
                <li><p><code>10.3 </code><u>Notices</u>. All notices, requests, consents, claims, demands, waivers, and
                  other similar communications hereunder (each, a &quot;<b><i>Notice</i></b>&quot;) shall be in writing and
                  addressed to the parties at the addresses set forth on the first page of this Agreement (or to such
                  other address that may be designated by the receiving party from time to time in accordance with this
                  Section). All Notices shall be delivered by (i) email (with confirmation of transmission, and
                  including the email in the signature block hereto) and (ii) personal delivery, nationally recognized
                  overnight courier (with all fees pre-paid), facsimile, or certified or registered mail (in each case,
                  return receipt requested, postage prepaid). Except as otherwise provided in this Agreement, a Notice
                  is effective only (a) upon receipt by the receiving party, and (b) if the party giving the Notice has
                  complied with the requirements of this Section.</p></li>
                <li><p><code>10.4 </code><u>Severability</u>. If any term or provision of this Agreement is invalid,
                  illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall
                  not affect any other term or provision of this Agreement or invalidate or render unenforceable such
                  term or provision in any other jurisdiction.</p></li>
                <li><p><code>10.5 </code><u>Amendment and Modification</u>. Client may, in its sole discretion, revise
                  and amend this Agreement at any time with notice, including by updating the “Last Updated” date at the
                  top of this Addendum.&nbsp; Your continued performance of the Services after the date of any such
                  revisions indicate your acceptance of any amendment to this Agreement.&nbsp;</p></li>
                <li><p><code>10.6 </code><u>Waiver</u>. No waiver by either party of any of the provisions hereof shall
                  be effective unless explicitly set out in writing and signed by the party so waiving. No waiver by any
                  party shall operate or be construed as a waiver in respect of any failure, breach, or default not
                  expressly identified by such written waiver, whether of a similar or different character, and whether
                  occurring before or after that waiver. No failure to exercise, or delay in exercising, any right,
                  remedy, power, or privilege arising from this Agreement shall operate or be construed as a waiver
                  thereof; nor shall any single or partial exercise of any right, remedy, power, or privilege hereunder
                  preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or
                  privilege. &nbsp;</p></li>
                <li><p><code>10.7 </code><u>Cumulative Remedies</u>. All rights and remedies provided in this Agreement
                  are cumulative and not exclusive, and the exercise by either party of any right or remedy does not
                  preclude the exercise of any other rights or remedies that may now or subsequently be available at
                  law, in equity, by statute, in any other agreement between the parties or otherwise.&nbsp;</p></li>
                <li><p><code>10.8 </code><u>Equitable Remedies</u>. Each party acknowledges and agrees that (a) a breach
                  or threatened breach by it of any of its obligations under Section 8 (Confidentiality) would give rise
                  to irreparable harm to the Client for which monetary damages would not be an adequate remedy and (b)
                  in the event of a breach or a threatened breach by of any such obligations, the Client shall, in
                  addition to any and all other rights and remedies that may be available to Client at law, at equity or
                  otherwise in respect of such breach, be entitled to equitable relief, including a temporary
                  restraining order, an injunction, specific performance and any other relief that may be available from
                  a court of competent jurisdiction, without any requirement to post a bond or other security, and
                  without any requirement to prove actual damages or that monetary damages will not afford an adequate
                  remedy. Each party agrees that it will not oppose or otherwise challenge the appropriateness of
                  equitable relief or the entry by a court of competent jurisdiction of an order granting equitable
                  relief, in either case, consistent with the terms of this Section.&nbsp;</p></li>
                <li><p><code>10.9 </code><u>Assignment</u>. Neither party may assign, transfer, or delegate any or all
                  of its rights or obligations under this Agreement, without the prior written consent of the other
                  party, which consent shall not be unreasonably withheld or delayed; provided, however, that Client may
                  assign this Agreement to an affiliate or successor-in-interest by consolidation, merger, or operation
                  of law or to a purchaser of all or substantially all of the party&apos;s assets. Any attempted assignment,
                  transfer, or other conveyance in violation of the foregoing shall be null and void. This Agreement
                  shall be binding upon and shall inure to the benefit of the parties hereto and their respective
                  successors and permitted assigns.</p></li>
                <li><p><code>10.10 </code><u>No Third-Party Beneficiaries</u>. &nbsp;</p>
                  <ul>
                    <li><p><code>(a) </code>Subject to this Section 10 this Agreement benefits solely the parties to
                      this Agreement and their respective permitted successors and assigns, and nothing in this
                      Agreement, express or implied, confers on any other person any legal or equitable right, benefit,
                      or remedy of any nature whatsoever under or by reason of this Agreement.</p></li>
                    <li><p><code>(b) </code>The parties hereby designate the Client Indemnified Parties and Service
                      Provider Indemnified Parties as third-party beneficiaries of this Agreement having the right to
                      enforce this Agreement.</p></li>
                  </ul>
                </li>
                <li><p><code>10.11 </code><u>Choice of Law</u>. This Agreement and all related documents including all
                  exhibits attached hereto, and all matters arising out of or relating to this Agreement, whether
                  sounding in contract, tort, or statute are governed by, and construed in accordance with, the laws of
                  the State of Delaware, United States of America, without giving effect to the conflict of laws
                  provisions thereof to the extent such principles or rules would require or permit the application of
                  the laws of any jurisdiction other than those of the State of Delaware.</p></li>
                <li><p><code>10.12 </code><u>Choice of Forum</u>. Each party irrevocably and unconditionally agrees that
                  it will not commence any action, litigation, or proceeding of any kind whatsoever against any other
                  party in any way arising from or relating to this Agreement and all contemplated transactions,
                  including, but not limited to, contract, equity, tort, fraud, and statutory claims, in any forum other
                  than Delaware Court of Chancery, and if such court does not have subject matter jurisdiction, the
                  courts of the State of Delaware, and any appellate court from any thereof.&nbsp; Each party
                  irrevocably and unconditionally submits to the exclusive jurisdiction of such courts and agrees to
                  bring any such action, litigation, or proceeding only in Delaware Court of Chancery, and if such court
                  does not have subject matter jurisdiction, the courts of the State of Delaware. Each party agrees that
                  a final judgment in any such action, litigation, or proceeding is conclusive and may be enforced in
                  other jurisdictions by suit on the judgment or in any other manner provided by law.</p></li>
                <li><p><code>10.13 </code><u>Waiver of Jury Trial</u>. EACH PARTY ACKNOWLEDGES AND AGREES THAT ANY
                  CONTROVERSY THAT MAY ARISE UNDER THIS AGREEMENT, INCLUDING ANY EXHIBITS, SCHEDULES, ATTACHMENTS, AND
                  APPENDICES ATTACHED TO THIS AGREEMENT, IS LIKELY TO INVOLVE COMPLICATED AND DIFFICULT ISSUES AND,
                  THEREFORE, EACH SUCH PARTY IRREVOCABLY AND UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY
                  JURY IN RESPECT OF ANY LEGAL ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT, INCLUDING ANY
                  EXHIBITS, SCHEDULES, ATTACHMENTS, OR APPENDICES ATTACHED TO THIS AGREEMENT, OR THE TRANSACTIONS
                  CONTEMPLATED HEREBY.</p></li>
                <li><p><code>10.14 </code><u>Force Majeure</u>. No party shall be liable or responsible to the other
                  party, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in
                  fulfilling or performing any term of this Agreement or any SOWs, when and to the extent such party&apos;s
                  (the &quot;<b><i>Impacted Party</i></b>&quot;) failure or delay is caused by or results from the following force
                  majeure events (&quot;<b><i>Force Majeure Event(s)</i></b>&quot;): (a) acts of God; (b) flood, fire, earthquake,
                  or explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or
                  acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in
                  effect on or after the date of this Agreement; and (f) national or regional emergency; and (g)
                  strikes, labor stoppages or slowdowns or other industrial disturbances; and (h) telecommunication
                  breakdowns, power outages or shortages, lack of warehouse or storage space, inadequate transportation
                  services, or inability or delay in obtaining supplies of adequate or suitable materials or (i) other
                  similar events beyond the reasonable control of the Impacted Party.</p></li>
                <li><p><code>10.15 </code><u>Relationship of Parties</u>. Nothing in this Agreement creates any agency,
                  joint venture, partnership, or other form of joint enterprise, employment, or fiduciary relationship
                  between the parties. Service Provider is an independent contractor pursuant to this Agreement. Neither
                  party has any express or implied right or authority to assume or create any obligations on behalf of
                  or in the name of the other party or to bind the other party to any contract, agreement, or
                  undertaking with any third party.</p></li>
                <li><p><code>10.16 </code><u>Counterparts. </u>This Agreement may be executed in counterparts, each of
                  which is deemed an original, but all of which together are deemed to be one and the same agreement. A
                  signed copy of this Agreement delivered by email or other means of electronic transmission is deemed
                  to have the same legal effect as delivery of an original signed copy of this Agreement.</p></li>
              </ul>
            </li>
          </ul>
          <h1><u><b>EXHIBIT A</b></u></h1><h2><b>Description of Services</b></h2><p>Service Provider will provide
            certain of the following Services as the Company may request from time to time, and otherwise as the Parties
            determine in writing:&nbsp;</p>
          <ul>
            <li><p>Provide endorsement of the Company’s products and services.&nbsp;</p></li>
            <li><p>Engage in promotional or marketing activities that support the Company’s products and services.</p>
            </li>
            <li><p>Design and produce creative materials to promote the Company.&nbsp;</p></li>
            <li><p>Manage relationships with users of the Company’s protocol in connection with promotions and campaigns
              run by Company.&nbsp;</p></li>
            <li><p>Assist with growth, marketing and community management as it relates to promotions and campaigns run
              by Company.&nbsp;</p></li>
            <li><p>Develop, create, and produce materials and content related to the following:&nbsp;</p>
              <ul>
                <li><p>contests;</p></li>
                <li><p>loyalty and reward programs;&nbsp;</p></li>
                <li><p>online promotional initiatives; and&nbsp;</p></li>
                <li><p>direct response initiatives.</p></li>
              </ul>
            </li>
          </ul>
          <h1><u><b>EXHIBIT B</b></u></h1><h2><b>Statement of Work</b></h2><p>[<i>Exhibit B intentionally left blank</i>]
          </p><h1><u><b>Exhibit C</b></u></h1><h2><b>Social Media and Promotion Endorsement Policy</b></h2><h3>Purpose
            and Scope</h3><p>Bitstaker Trading Inc., a Delaware corporation (“<b><i>Bitstaker</i></b>” or the
            “<b><i>Company</i></b>” or “<b><i>us</i></b>”), is committed to transparency and honesty in all of its
            advertising messages and promotional communications with consumers. One of the most common ways to advertise
            and market products and services in social media is to use third party endorsements. Like other types of
            advertising, endorsements must be truthful and not misleading. Consumers must understand when a social media
            endorsement is sponsored by us.&nbsp;</p><p>This Policy applies to all agencies, independent contractors,
            speakers, writers, bloggers, talent, influencers, and any other individual or entity engaged in promotional
            communications on behalf of the Company on social media or other non-traditional media. This Policy also
            applies to our employees and agencies who manage these individuals and entities and employees who promote
            the company and its products and services on their personal social media accounts.&nbsp;</p><h3>Defining an
            Endorser Covered by This Policy</h3><p>An individual or entity communicating on social media or other
            non-traditional media is covered by this Policy if its promotional messages about the Company or our
            products and services are sponsored by us (&quot;<b><i>Sponsored Endorser</i></b>&quot;). If the individual or entity
            is acting independently, and has no material connection with us, and will not receive (and does not expect
            to receive) any compensation directly or indirectly from the Company, it may not be subject to this
            Policy.&nbsp;</p><p>A message is sponsored by us if we have a material connection with the individual or
            entity. A material connection is a tie to us which if known to consumers might make consumers question the
            credibility of the endorser or materially affect the weight consumers place on the endorsement. It puts the
            endorser&apos;s independence in question. For example, the Company or any brand creates a material connection if
            we do any of the following either directly or through an advertising agency, public relations firm, or other
            third party:&nbsp;</p>
          <ol>
            <li><p>Hire an agency to blog, post, or serve as a community manager on our behalf (both the agency and its
              employees then become Sponsored Endorsers).</p></li>
            <li><p>Enter into an agreement with an individual to blog or post.</p></li>
            <li><p>Pay an individual to blog or post.</p></li>
            <li><p>Provide free accommodations or travel to an individual for a company event or experience.</p></li>
            <li><p>Provide discounts, sweepstakes entries, or other incentives to an individual.</p></li>
            <li><p>Provide an individual with free prizes for giveaways or sweepstakes on social media platforms.</p>
            </li>
            <li><p>Provide an individual with free samples to review on social media platforms.</p></li>
            <li><p>Provide an individual with free samples after that person has blogged or posted independently,
              especially if providing the free samples creates the expectation of additional free samples (which makes
              the individual a Sponsored Endorser going forward, not retroactively).</p></li>
            <li><p>Engage affiliate marketers to advertise, blog, endorse, or sell on our behalf (making the affiliates
              and their employees Sponsored Endorsers).</p></li>
            <li><p>The Company can turn our everyday consumers into Sponsored Endorsers by:</p></li>
            <li><p>Establishing a consumer marketing program that gives members free products and services, coupons,
              discounts, or other benefits.</p></li>
            <li><p>Providing incentives to consumers to review our products and services.</p></li>
            <li><p>Requiring participants to post photos of our product or brand.</p></li>
          </ol>
          <p>This list, though comprehensive, is not exhaustive. Note that the Company employees have a material
            connection to the Company so are also considered to be Sponsored Endorsers.&nbsp;</p><h3>Sponsored Endorsers
            Must Comply with Our Standards of Conduct</h3><p>With respect to promotional statements or other claims made
            on social media platforms and on other non-traditional media about the Company and our products and
            services, Sponsored Endorsers must adhere to the following principles:</p><p>They may only make statements
            that:</p>
          <ol>
            <li><p>reflect their honest beliefs, opinions, or experiences; and</p></li>
            <li><p>are transparent about their connection to us.</p></li>
          </ol>
          <p>They may not:&nbsp;</p>
          <ol>
            <li><p>make deceptive or misleading claims to consumers about our products or services, or our competitors&apos;
              products or services;</p></li>
            <li><p>make any claims about our products or services, or our competitors&apos; products or services, that are
              not backed up by evidence;</p></li>
            <li><p>disclose any of our confidential information;</p></li>
            <li><p>engage in any communication that is defamatory or infringes upon the intellectual property, privacy,
              or publicity rights of others;</p></li>
            <li><p>offer for sale or solicit products on behalf of the Company;</p></li>
            <li><p>make offensive comments that have the purpose or effect of creating an intimidating or hostile
              environment;</p></li>
            <li><p>use ethnic slurs, personal insults, obscenity, or other offensive language; and</p></li>
            <li><p>make any comments or post any content that in any way promotes unsafe activities that could lead to
              an unsafe situation involving the Company&apos;s consumers or other individuals.</p></li>
          </ol>
          <p>They must adhere to:</p>
          <ol>
            <li><p>the posted guidelines and terms of use on any site on which they post content on behalf of the
              Company; and</p></li>
            <li><p>any additional guidelines provided by the Company.</p></li>
            <li><p>Sponsored Endorsers must also refrain from creating fake followers or engagement on social media
              platforms, such as buying followers, or using bots to grow audience size by automating account creation,
              following, commenting, and liking.</p></li>
          </ol>
          <h3>Disclosing a Material Connection Clearly and Conspicuously</h3><p>When posting about our products and
            services, the Company requires Sponsored Endorsers to disclose their material connection to us clearly and
            conspicuously. If a Sponsored Endorser has multiple material connections to us, the full extent should be
            disclosed.</p><p>This policy does not require specific language to disclose a material connection, but
            Sponsored Endorsers must communicate the material connection effectively so that consumers:</p>
          <ol>
            <li><p>Can easily find it.</p></li>
            <li><p>Can easily understand it.</p></li>
            <li><p>Obtain sufficient information from it to make a judgment about the credibility of the
              endorsement.</p></li>
            <li><p>Consult Appendix A to this Policy for examples of language that successfully communicate a material
              connection and language that does not do so.</p></li>
          </ol>
          <p>To ensure a disclosure is clear and conspicuous, appropriate consideration should be given to the
            limitations and nature of the platform being used. Sponsored Endorsers must:</p><p>Ensure the disclosure
            is:&nbsp;</p>
          <ol>
            <li><p>well-placed so it can be easily noticed; and</p></li>
            <li><p>prominent so it can be easily read.</p></li>
            <li><p>Avoid burying the disclosure:</p></li>
            <li><p>in a bio;</p></li>
            <li><p>below the fold;</p></li>
            <li><p>in a hyperlink, like a &quot;Legal&quot; or &quot;Disclosure&quot; button; or</p></li>
            <li><p>among a series of hashtags, other disclosures, or general copy.</p></li>
          </ol>
          <p>Superimpose a material connection disclosure on images, including on Snapchat and Instagram Stories. The
            disclosure should be:</p>
          <ol>
            <li><p>easy to notice and read in the time that followers have to look at the image; and</p></li>
            <li><p>well-contrasted against the image.</p></li>
          </ol>
          <p>In video posts, including podcasts:&nbsp;</p>
          <ol>
            <li><p>place the disclosure both within the video itself, and in the description of the video; and</p></li>
            <li><p>display the disclosure long enough for a consumer to be able to read and understand it.</p></li>
          </ol>
          <p>In Instagram posts, disclose a material connection before the &quot;More&quot; button.</p><p>For a live stream,
            repeat the disclosure as needed to ensure that consumers see it or keep it posted throughout the live
            stream.</p><p>If the posts are part of an online chat or tweets, or a similar thread, make the disclosure
            clearly in the first entry into the conversation thread, and then add to subsequent entries.</p><p>Disclose
            the material connection even when just tagging a brand in a photo.</p><p>For a podcast appearance, disclose
            the material connection verbally when promoting our products and services.</p><p>If we ask consumers to
            promote our products and services on social media in exchange for the chance to win a prize, the official
            rules must require entrants to disclose the fact that the entrant&apos;s post is an entry into a contest. Whether
            the products and services are promoted in a text, a hashtag, a photo, or a video, the entry post must
            include some clear and conspicuous indicator that the consumer has received an entry in a promotion in
            exchange for the post. For example, the official rules could require entrants to use a specific hashtag
            disclosing that the post is a contest entry. Failure to make the disclosure is a breach of this policy.</p>
          <p>General guidance on the FTC Endorsement Guides and encouragement to educate themselves on all FTC
            endorsement guidance. For example, inform Sponsored Endorsers that:</p>
          <ol>
            <li><p>they should not assume their followers know about their connection to us;</p></li>
            <li><p>opinions should be based on their own honest beliefs and experiences;</p></li>
            <li><p>they should refrain from making statements about our products and services that are measurable, other
              than those on the fact sheets we have provided; and</p></li>
            <li><p>any comparisons must be based on actual, personal experiences with all products being compared
              because they should not extrapolate beyond personal experiences.&nbsp;</p></li>
          </ol>
          <p>Sponsored Endorsers posts will be monitored to ensure the accuracy of any measurable claims they make about
            our products and services and that they have adequately disclosed their material connection to us.</p>
          <h3>When a Sponsored Endorser Fails to Comply with This Policy</h3><p>You must correct any failure to disclose
            a material connection or communication of any unsubstantiated claims by a Sponsored Endorser. The employee
            team responsible for the Sponsored Endorser will take the following steps:</p><p>If an agency or other third
            party manages or is otherwise involved with the Sponsored Endorser relationship, alert the third party to
            the issue.</p><p>Consult with the Company to determine whether a correction is needed, and how best to make
            it.</p><p>If needed, Sponsored Endorser should make the correction or post the correction on the Sponsored
            Endorser&apos;s post or page directly.</p><p>The parties will determine if it is necessary to, and the Client
            shall have the right to decide, in its sole discretion, whether to:</p>
          <ol>
            <li><p>withhold payment from the Sponsored Endorser; or</p></li>
            <li><p>terminate the relationship with the Sponsored Endorser.</p></li>
          </ol>
          <h3>Acknowledgment of Receipt and Review</h3><p>Any person providing promotion or endorsement services for the
            Company agrees, indicated by such action, that such person has received and this policy and understands and
            agrees to comply with its terms. By providing promotional or endorsement services for the Company, you agree
            that you understand that the Company expressly reserves the right to change, modify, or delete the
            provisions of this policy at any time without notice.&nbsp;</p><h1><u>Appendix A&nbsp;to Social Media and
            Promotion Endorsement Policy</u></h1><h2><b>Best Practices for Disclosure Language</b></h2><p>As stated in
            the Policy, Sponsored Endorsers are required to disclose material connections to the Company. For certain
            campaigns, the Company may require Sponsored Endorsers to use specific disclosure language.&nbsp; For other
            campaigns, listed below is sample disclosure language. Alternative but substantively comparable language may
            also be used where appropriate. The Company prohibits certain hashtags and other disclosure practices
            specifically found by the FTC as insufficient. The goal for a material connection disclosure is to ensure it
            is readily seen and understood by consumers and accurately describes our relationship with the Sponsored
            Endorser.</p><p>Statements about the material connection should identify and disclose the nature of the
            connection, such as:</p><p>For receipt of free products:</p>
          <ul>
            <li><p>I received consideration from Bitstaker Trading Inc.;&nbsp;</p></li>
          </ul>
          <p>For paid Sponsored Endorsers:</p>
          <ul>
            <li><p>I have partnered with Bitstaker to promote its products and services; or</p></li>
            <li><p>I am a paid brand ambassador for Bitstaker.</p></li>
          </ul>
          <p>For receipt of a prize to be given away in a contest:</p>
          <ul>
            <li><p>Bitstaker is providing prize money for this program at no cost to me; or</p></li>
            <li><p>This program is not administered or sponsored by Bitstaker or its affiliates, but solely by your company
              name.</p></li>
          </ul>
          <p>For incentivized consumer reviews:&nbsp;</p>
          <ul>
            <li><p>I received consideration in exchange for making this review.</p></li>
          </ul>
          <p>For other material connections that should be disclosed in your communications:</p>
          <ul>
            <li><p>&quot;Advertisement&quot;;</p></li>
            <li><p>&quot;Sponsored&quot;;</p></li>
            <li><p>&quot;Paid ad&quot;; or</p></li>
            <li><p>&quot;Ad:&quot; (this would go at the beginning of the statement to indicate the statement is an ad).</p></li>
          </ul>
          <p>If using a hashtag to identify a material connection, make it unambiguous, such as:</p>
          <ul>
            <li><p>#ad</p></li>
            <li><p>#paid</p></li>
            <li><p>#sponsored</p></li>
            <li><p>#Bitstakerambassador</p></li>
            <li><p>#Bitstakerendorser</p></li>
          </ul>
          <p>Avoid ambiguous hashtags to identify material connections, such as:</p>
          <ul>
            <li><p>#sp</p></li>
            <li><p>#spon</p></li>
            <li><p>#thanksBitstaker</p></li>
            <li><p>#teamBitstaker</p></li>
            <li><p>#ambassador</p></li>
            <li><p>#consultant</p></li>
            <li><p>#partner</p></li>
            <li><p>#adviser</p></li>
            <li><p>#collab</p></li>
          </ul>
          <p></p></div>
      </article>
      <Footer/>
    </div>
  )
}
