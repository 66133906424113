import img1 from "assets/icons/chains/4.svg"

export const poolDataArb = [
  {
    icon: img1,
    name: "Arbitrum One",
    token: "ARB",
    network: "Arbitrum One",
    id: "arbitrum",
    addresses: [
      "0x83a93621f11922434445cf1914edd55674d6fc56",
      "0x544cbe6698e2e3b676c76097305bba588defb13a",
      "0x97772ff955ce7c136f9d7db5414e197da25a9c72",
      "0x36575e3dc451e2d673d886ae8e11efb9707aa61f",
    ],
    quantity: ["20000-40000", "40000-60000", "50000-75000", "75000-200000"],
    percents: ["1.2", "3.1", "7.9", "13.5"],
    days: ["14", "30", "60", "90"],
  },
]
