import img1 from "assets/icons/chains/7.svg"

export const poolDataBase = [
  {
    icon: img1,
    name: "Base",
    token: "ETH(Base)",
    network: "Base",
    id: "ethereum",
    addresses: [
      "0x6404b20b5a8493c426b6efbe52809b206b26d393",
      "0x675d5cebae87ecb8acc7f057e95156f7be9697d1",
      "0xc32df201476bb79318c32fd696b2ccdcc5f9a909",
      "0x9b4a1130c5d943ff9d97d0fc7c58d09df9ad9fa1",
    ],
    quantity: ["0.3-5", "3-10", "5-25", "9-50"],
    percents: ["0.8", "2", "5", "8.5"],
    days: ["14", "30", "60", "90"],
  },
]
