import img1 from "assets/icons/chains/3.svg"

export const poolDataOpt = [
  {
    icon: img1,
    name: "Optimism",
    token: "OP",
    network: "Optimism",
    id: "optimism",
    addresses: [
      "0x00080706a7d99cbc163d52dcf435205b1ad940d1",
      "0xd25711edfbf747efce181442cc1d8f5f8fc8a0d3",
      "0xb533c12fb4e7b53b5524eab9b47d93ff6c7a456f",
      "0x8ed1609d796345661d36291b411992e85de7b224",
    ],
    quantity: ["7000-10000", "10000-30000", "20000-50000", "50000-100000"],
    percents: ["1.4", "3.5", "8.7", "14.8"],
    days: ["14", "30", "60", "90"],
  },
]
