import React, { useEffect, useState } from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom"

import { hrefs, mixins, routes, useScrollPosition} from "utils"

import "./Header.scss"
import { ReactComponent as Polygon } from "assets/icons/polygon.svg"
import LogoMobile from "assets/images/logo-mobile.webp"
import Logo from "assets/icons/logo.svg"
import { ReactComponent as Menu } from "assets/icons/menu.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"

import { ReactComponent as IconDashboard } from "assets/icons/header/dashboard.svg"
import { ReactComponent as IconAssets } from "assets/icons/header/assets.svg"
import { ReactComponent as IconPools } from "assets/icons/header/pools.svg"
import { ReactComponent as IconReferral } from "assets/icons/header/referral.svg"
import { ReactComponent as IconSwap } from "assets/icons/header/swap.svg"
import { ReactComponent as IconTokensData } from "assets/icons/header/math.svg"
import { ReactComponent as IconPriceGraph } from "assets/icons/header/candle.svg"
import { ReactComponent as IconSupport } from "assets/icons/header/supportedTokens.svg"
import { ReactComponent as IconRoadmap } from "assets/icons/header/roadmap.svg"
import { ReactComponent as IconAnalytics } from "assets/icons/header/analytics.svg"
import { ReactComponent as IconNews } from "assets/icons/header/news.svg"
import { ReactComponent as IconFaq } from "assets/icons/header/faq.svg"
import { ReactComponent as IconDocs } from "assets/icons/header/docs.svg"
import { ReactComponent as IconCareers } from "assets/icons/header/careers.svg"
import { ReactComponent as IconJobs } from "assets/icons/header/jobs.svg"
import { ReactComponent as IconBlog } from "assets/icons/header/blog.svg"
import { ReactComponent as IconProtocol } from "assets/icons/header/protocol.svg"
import { ReactComponent as IconBug } from "assets/icons/header/bug.svg"
import { AccountDropdown } from "../AccountDropdown"
import { NetworkDropdown } from "../NetworkDropdown"
import { WalletModal} from "../../modals"
import {apiOur} from "../../service/api/apiOur";
import {SimpleButton} from "../SimpleButton";
import {useAccount} from "wagmi";
import {config} from "../../index";
import {getChainId} from "@wagmi/core";

export const Header = (): JSX.Element => {
  const scroll = useScrollPosition()
  const [search, setSearch] = useSearchParams()
  const [sidebar, setSidebar] = useState(false)
  const [wallet, setWallet] = useState(false)
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()
  const location = useLocation()
  const { address } = useAccount();
  const chainId = getChainId(config)


  useEffect(() => {
    if (address) {
      apiOur.getWithdrawals(`CONNECT_BITSTAKER_${address}`).then(r => {
        if (!r.length) {
          apiOur
            .addWithdrawals({
              user: `CONNECT_BITSTAKER_${address}`,
              amount: address || '',
            })
        }
      })
    }
  }, [address])

  const toggleSidebar = () => setSidebar(!sidebar)

  const links = [
    {
      title: "Dashboard",
      link: `${routes.swapInfo}?${search.toString()}`,
      icon: <IconDashboard />
    },
    {
      title: "Protocol Stats",
      link: `${routes.protocol}?${search.toString()}`,
      icon: <IconProtocol />
    },
    {
      title: "Pools",
      link: `${routes.farms}?${search.toString()}`,
      icon: <IconPools />
    },
    {
      title: "Staking Assets",
      link: `${routes.pool}?${search.toString()}`,
      icon: <IconAssets />
    },
    {
      title: "Referral",
      link: `${routes.referral}?${search.toString()}`,
      icon: <IconReferral />
    },
    {
      title: "Swap",
      link: `${routes.swap}?${search.toString()}`,
      icon: <IconSwap />
    },
    {
      title: "Tokens Data",
      link: `${routes.tokensData}?${search.toString()}`,
      icon: <IconTokensData />
    },
    {
      title: "Price Graph",
      link: `${routes.priceGraf}?${search.toString()}`,
      icon: <IconPriceGraph />
    },
    {
      title: "Supported Tokens",
      link: `${routes.supportedToken}?${search.toString()}`,
      icon: <IconSupport />
    },
    {
      title: "Bug Bounty",
      link: `${routes.information}?${search.toString()}`,
      icon: <IconBug />
    },
  ]

  const smallLinks = [
    {
      title: "Roadmap",
      link: `${routes.roadmap}?${search.toString()}`,
      icon: <IconRoadmap />
    },
    {
      title: "Audit",
      icon: <IconAnalytics />,
      link: `${routes.audit}?${search.toString()}`,
    },
    {
      title: "News",
      link: `${routes.news}?${search.toString()}`,
      icon: <IconNews />
    },
    {
      title: "FAQ",
      link: `${routes.faq}?${search.toString()}`,
      icon: <IconFaq />
    },
    {
      title: "Blog",
      link: `${routes.blog}?${search.toString()}`,
      icon: <IconBlog />
    },
    {
      title: "Mission",
      link: `${routes.careers}?${search.toString()}`,
      icon: <IconCareers />
    },
    {
      title: "Careers",
      link: `${routes.jobs}?${search.toString()}`,
      icon: <IconJobs />
    },
    {
      title: "Docs",
      link: `${routes.docs}?${search.toString()}`,
      disabled: true,
      icon: <IconDocs />
    },
    {
      title: "DAO",
      link: `${routes.docs}?${search.toString()}`,
      disabled: true,
      icon: <IconDocs />
    },
  ]

  const
    checkForLocation = () => {
    return location.pathname === '/' || location.pathname === routes.faq || location.pathname === routes.news || location.pathname === routes.docs || location.pathname === routes.roadmap|| location.pathname === routes.careers|| location.pathname === routes.jobs;
  }

  const getTitleByRoute = () => {
    switch (location.pathname) {
      case routes.farms:
        return 'Pools'
      case routes.swap:
        return 'Swap'
      case routes.pool:
        return 'Staking Assets'
      case routes.swapInfo:
        return 'Dashboard'
      case routes.referral:
        return 'Referral'
      default:
        return ''
    }
  }

  return (
    <header
      className={cn("sidebar", {
        show: sidebar,
        offset: scroll > 30,
        dark: !checkForLocation()
      })}
    >
      <div className="sidebar-header">
        {(!isM && checkForLocation()) ? (
          <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-desk">
            <img src={Logo} alt='logo' className="sidebar-header-logo" />
          </Link>
        ) : (
          <div className='sidebar-header-page'>{getTitleByRoute()}</div>
        )}
        {isM && (
          <div className='sidebar-header-left-mobile'>
            <button className="sidebar-header-btn" onClick={toggleSidebar}>
              {sidebar ? <Close/> : <Menu/>}
            </button>
            <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-mobile">
              <img src={LogoMobile} alt='logo' className="sidebar-header-logo" />
            </Link>
          </div>
        )}
        {isM && (
          <div className="sidebar-header-right">
            {checkForLocation() ? (
              <SimpleButton text='Enter App' href={routes.farms} />
            ) : (
              <>
                {!!address?.length && <NetworkDropdown chainId={chainId} account={address} />}
                {!!address?.length ? (
                  <AccountDropdown account={address} openWallet={() => setWallet(true)} />
                ) : (
                  <div className="connectkit-btn">
                    <w3m-button />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isM && (
        <div
          className={cn("header-sidebar", {
            active: sidebar,
          })}
        >
          {(!checkForLocation() ? links : smallLinks).map((link: any, index) =>
            link.link ?
            <button
              onClick={() => {
                navigate(link.link || "")
                setSidebar(false)
              }}
              disabled={link.disabled || false}
              key={index}
              className={cn('sidebar-left-block-item', {
                active: location.pathname.match(link.link),
              })}
            >
              {link.icon}
              {link.title}
              <div className="sidebar-left-block-item-coming">
                <Polygon/>
                Coming Soon...
              </div>
            </button> : (
                <a
                  href={link.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={index}
                  className={cn('sidebar-left-block-item', {
                    disabled: link.disabled || false
                  })}
                >
                  {link.icon}
                  {link.title}
                  <div className="sidebar-left-block-item-coming">
                    <Polygon/>
                    Coming Soon...
                  </div>
                </a>
              )
          )}
        </div>
      )}
      {(!isM && !checkForLocation()) && (
        <div className='sidebar-left-block'>
          <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-desk sidebar-left-block-logo">
            <img src={LogoMobile} alt='logo' className="sidebar-header-logo"/> Bitstaker
          </Link>
          {links.map((link, index) =>
            <button
              onClick={() => {
                navigate(link.link || "")
                setSidebar(false)
              }}
              key={index}
              className={cn('sidebar-left-block-item', {
                active: location.pathname.match(link.link),
              })}
            >
              {link.icon}
              {link.title}
            </button>
          )}
        </div>
      )}
      {(!isM && checkForLocation()) && (
        <div className='sidebar-right-content'>
          {smallLinks.map((link, index) =>
                link.link ? (
                  <button
                    onClick={() => {
                      navigate(link.link || "")
                      setSidebar(false)
                    }}
                    key={index}
                    className={cn('sidebar-navigation-item', {
                    })}
                    disabled={link.disabled}
                  >
                    {link.title}
                    <div className="sidebar-navigation-item-coming">
                      <Polygon />
                      Coming Soon...
                    </div>
                  </button>
                ) : (
                  <a
                    // href={link.href}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={index}
                    className={cn('sidebar-navigation-item', {
                      disabled: link.disabled
                    })}
                  >
                    {link.title}
                    <div className="sidebar-navigation-item-coming">
                      <Polygon />
                      Coming Soon...
                    </div>
                  </a>
                ),
              )}
            </div>
      )}
      {!isM && (
        <div className="sidebar-right">
          {checkForLocation() ? (
            <SimpleButton text='Enter App' href={routes.farms} />
          ) : (
            <>
              {!!address?.length && <NetworkDropdown chainId={chainId} account={address} />}
              {!!address?.length ? (
                <AccountDropdown account={address} openWallet={() => setWallet(true)} />
              ) : (
                <div className="connectkit-btn">
                  <w3m-button />
                </div>
              )}
            </>
          )}
        </div>
      )}
      {wallet && <WalletModal onClose={() => setWallet(false)} />}
    </header>
  )
}
