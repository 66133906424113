import img1 from "assets/icons/chains/6.svg"

export const poolDataAvax = [
  {
    icon: img1,
    name: "Avalanche",
    token: "AVAX",
    network: "Avalanche",
    id: "avalanche-2",
    addresses: [
      "0xdaD3dD2f5150a557a8bF88e12EB720b85C25c0E9",
      "0x5C561cB00ad6A4b75909655862F1C047D118A892",
      "0xd2980CA4BD349Fb877397b7bf588636d2d0DfB36",
      "0x3d80E0A69cf26CdBcCF90131912f3F85106613CF",
    ],
    quantity: ["500-1500", "1000-3000", "3000-4500", "4000-8000"],
    percents: ["1.9", "4.9", "12.2", "20.8"],
    days: ["14", "30", "60", "90"],
  },
]
