import img1 from "assets/icons/chains/6.svg"

const tokensText = [
  {
    token: "AVAX",
    tokenDesc: "Avalanche is a layer one blockchain that functions as a platform for decentralized applications and custom blockchain networks. It is one of Ethereum’s rivals, aiming to unseat Ethereum as the most popular blockchain for smart contracts. It aims to do so by having a higher transaction output of up to 6,500 transactions per second while not compromising scalability.",
    site: "https://www.avax.network/"
  },
];

export const poolDataSeparatedAvax = [
  {
    icon: img1,
    name: "Avalanche",
    token: "AVAX",
    network: "Avalanche",
    id: "avalanche-2",
    address: "0xdaD3dD2f5150a557a8bF88e12EB720b85C25c0E9",
    addAddress: null,
    quantity: "500-1500",
    index: 1,
    percent: "1.9",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Avalanche",
    token: "AVAX",
    network: "Avalanche",
    id: "avalanche-2",
    address: "0x5C561cB00ad6A4b75909655862F1C047D118A892",
    addAddress: null,
    quantity: "1000-3000",
    percent: "4.9",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Avalanche",
    token: "AVAX",
    network: "Avalanche",
    id: "avalanche-2",
    address: "0xd2980CA4BD349Fb877397b7bf588636d2d0DfB36",
    addAddress: null,
    quantity: "3000-4500",
    percent: "12.2",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Avalanche",
    token: "AVAX",
    network: "Avalanche",
    id: "avalanche-2",
    address: "0x3d80E0A69cf26CdBcCF90131912f3F85106613CF",
    addAddress: null,
    quantity: "4000-8000",
    percent: "20.8",
    day: "90",
    text: tokensText[0],
    index: 4
  },
]
