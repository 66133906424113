import img1 from "assets/icons/chains/5.svg"

const tokensText = [
  {
    token: "FTM",
    tokenDesc: "Fantom is a directed acyclic graph (DAG) smart contract platform providing decentralized finance (DeFi) services to developers using its own bespoke consensus algorithm.\n" +
      "Together with its in-house token FTM, Fantom aims to solve problems associated with smart-contract platforms, specifically transaction speed, which developers say they have reduced to under two seconds.",
    site: "https://fantom.foundation/"
  },
];

export const poolDataSeparatedFantom = [
  {
    icon: img1,
    name: "Fantom Opera",
    token: "FTM",
    network: "Fantom Opera",
    id: "fantom",
    address: "0x3ddcb7117b4c5f609b61e15154a56b359ab8a7fe",
    addAddress: null,
    quantity: "25000-50000",
    index: 1,
    percent: "1.6",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Fantom Opera",
    token: "FTM",
    network: "Fantom Opera",
    id: "fantom",
    address: "0xb458bfc855ab504a8a327720fcef98886065529b",
    addAddress: null,
    quantity: "30000-75000",
    percent: "4",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Fantom Opera",
    token: "FTM",
    network: "Fantom Opera",
    id: "fantom",
    address: "0x7f9056018c742bf25fbe6f4b553581af54f308fc",
    addAddress: null,
    quantity: "75000-100000",
    percent: "10",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Fantom Opera",
    token: "FTM",
    network: "Fantom Opera",
    id: "fantom",
    address: "0xb8e6d31e7b212b2b7250ee9c26c56cebbfbe6b23",
    addAddress: null,
    quantity: "100000-300000",
    percent: "17",
    day: "90",
    text: tokensText[0],
    index: 4
  },
]
