import "./WalletModal.scss"
import { SimpleButton } from "../../components"
import { copyToClipboard } from "../../utils"
import { useEffect, useState } from "react"
import { ReactComponent as Close } from "assets/icons/close-modal.svg"
import { ReactComponent as Copy } from "assets/icons/copy.svg"
import { ReactComponent as Eth } from "assets/icons/pool/eth.svg"
import { ReactComponent as LinkIcon } from "assets/icons/export.svg"
import { ReactComponent as Danger } from "assets/icons/danger.svg"
import {useAccount, useDisconnect, useBalance, useWalletClient} from "wagmi";
import { getBalance } from '@wagmi/core'
import {config} from "../../index";

type Props = {
  onClose: () => void
}

export const WalletModal = ({ onClose }: Props): JSX.Element => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect()
  const [balance, setBalance] = useState(0)
  const body = document.body

  useEffect(() => {
    body.style.overflow = "hidden"
  }, [body.style.overflow])

  useEffect(() => {
    if (!!address?.length) {
      const balance = getBalance(config, {
        address: address,
      })
      balance.then(r => setBalance(Number(r.formatted)))
    }

  }, [address])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
  }

  return (
    <div className="wallet-modal-wrapper">
      <div className="wallet-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="wallet-modal-content-title">Your Wallet</h2>
        <button onClick={handleClose} className="wallet-modal-content-close">
          <Close />
        </button>
        <div className="wallet-modal-content-address-title">Your Address</div>
        <button onClick={() => copyToClipboard(address || "")} className="wallet-modal-content-address-value">
          {address} <Copy />
        </button>
        <div className="wallet-modal-content-block">
          <div className="wallet-modal-content-block-left">
            <Eth /> Ethereum
          </div>
          <a
            target="_blank"
            rel="noreferrer noopner"
            href={`https://etherscan.io/address/${address}`}
            className="wallet-modal-content-block-link"
          >
            Etherscan <LinkIcon />{" "}
          </a>
        </div>
        <div className="wallet-modal-content-block">
          <div className="wallet-modal-content-block-left">ETH Balance</div>
          <div className="wallet-modal-content-block-value">{balance}</div>
        </div>
        <div className="wallet-modal-content-attention">
          <Danger />
          <div>Lock COIN to enjoy the benefits of profit boosting!</div>
        </div>
        <SimpleButton
          text="Disconnect Wallet"
          onClick={() => {
            disconnect()
          }}
          variant="border"
        />
      </div>
    </div>
  )
}
