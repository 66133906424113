import React, { useRef, useState } from "react"
import cn from "classnames"

import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg"
import { ReactComponent as Logout } from "assets/icons/logout.svg"
import "./AccountDropdown.scss"
import { useClickOutside } from "../../utils"
import { useDisconnect } from "wagmi";

type Props = {
  account: string
  openWallet: (type: string) => void
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const AccountDropdown = ({ account, openWallet }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const {disconnect} = useDisconnect();

  useClickOutside(wrapperRef, () => {
    setOpen(false)
  })

  const toggleDropdown = () => setOpen(!open)

  const onDisconnect = () => {
    disconnect()
    window.localStorage.removeItem("provider")
    setCookie('logged', 'false', 365)
  }

  return (
    <div
      className={cn("account-dropdown", {
        active: open,
      })}
    >
      <button onClick={toggleDropdown} className="account-dropdown-trigger">
        {`${account.slice(0, 2)}...${account.slice(account.length - 4, account.length)}`}
        <Arrow />
      </button>
      <div className="account-dropdown-content">
        <button
          onClick={() => {
            setOpen(false)
            openWallet("wallet")
          }}
          className="account-dropdown-content-item"
        >
          Wallet
        </button>
        {/*<button*/}
        {/*  onClick={() => {*/}
        {/*    setOpen(false)*/}
        {/*    openWallet("transactions")*/}
        {/*  }}*/}
        {/*  className="account-dropdown-content-item"*/}
        {/*>*/}
        {/*  Recent Transactions*/}
        {/*</button>*/}
        <div className="account-dropdown-content-divider" />
        <button onClick={onDisconnect} className="account-dropdown-content-item">
          Disconnect <Logout />
        </button>
      </div>
    </div>
  )
}
