import img1Src from 'assets/images/blog-post.webp'
import img2Src from 'assets/images/blog/1.webp'
import img3Src from 'assets/images/blog/2.webp'
import img4Src from 'assets/images/blog/3.webp'
import img5Src from 'assets/images/blog/4.webp'
import img6Src from 'assets/images/blog/5.webp'
import img7Src from 'assets/images/blog/6.webp'

export const blogData = [
  {
    id: 'about',
    image: img1Src,
    title: "Bitstaker: Revolutionizing Staking in DeFi",
    text: "Bitstaker is a next-generation decentralized finance (DeFi) platform that empowers users to earn high returns by staking their digital assets across multiple blockchain networks. We’ve built a secure, user-friendly environment where both novice and experienced crypto holders can easily stake a variety of tokens and maximize their earnings without the need for intermediaries.\n\n" +
      "Our platform supports a wide range of blockchain networks, including Ethereum, BNB Chain, Optimism, and more, offering over 60+ staking pools across multiple tokens. We’ve designed Bitstaker with a clear focus on flexibility and transparency, allowing users to diversify their investments and optimize returns.\n" +
      "One of the core principles of Bitstaker is ensuring that users reap the rewards. 98% of the fees and profits generated on the platform are distributed directly to staking users, while Bitstaker retains just 2%, ensuring that the community benefits from the platform’s success.\n\n" +
      "We have also partnered with leading blockchain service providers like Chainlink to integrate oracle solutions, and we use CoinMarketCap and CoinGecko APIs to offer real-time, accurate data, enhancing the staking experience. Our smart contracts have undergone rigorous security audits, ensuring that users can trust in the safety of their assets.\n\n" +
      "Looking ahead, Bitstaker is committed to expanding its platform, supporting even more blockchain networks Manta, and launching new staking pools. With strong backing from institutional investors and a commitment to innovation, we aim to continue growing as a leader in the DeFi staking space.\n\n" +
      "Join Bitstaker today and unlock the full potential of your crypto assets through secure, high-yield staking opportunities.\n\n",
    time: '1 min read time'
  },
  {
    id: 'review-2',
    image: img7Src,
    title: " Bitstaker Q2 2024 Review: Major Integrations, New Partnerships, and 167% TVL Growth",
    text: `<p>
      As we conclude the second quarter of 2024, Bitstaker continues to make significant strides in the decentralized
      finance (DeFi) ecosystem. From strategic integrations to ambitious new projects, this quarter has been marked by
      considerable growth and innovation. Here’s a look at the key highlights from <strong>Q2 2024</strong>:<br/><br/>
      <strong>1. IPFS Integration for Enhanced Data Security</strong><br/><br/>
      In Q2, we successfully integrated <strong>IPFS</strong> (InterPlanetary File System) into the Bitstaker platform.
      This move enhances the security and availability of our data by leveraging decentralized storage. With IPFS, users
      benefit from a more efficient and secure method of storing and retrieving data, further bolstering the robustness
      of our platform.<br/><br/>
      <strong>2. Strengthening Our Partnership with Uniswap</strong><br/><br/>
      Building on our existing relationship with Uniswap, Bitstaker has now integrated <strong>Uniswap’s swap function</strong>, allowing
      users to swap assets seamlessly on our platform. This partnership is solidified by the continued success of our
      <strong>UNI</strong> staking pool, which provides users with attractive staking opportunities. The deeper integration with Uniswap
      further positions Bitstaker as a leading platform for staking and DeFi services.<br/><br/>
      <strong>3. Developing DAO and Cross-Chain Bridge</strong><br/><br/>
      Looking ahead, Bitstaker is actively working on the development of <strong>DAO (Decentralized
      Autonomous Organization)</strong>
      functionality. Our team is currently developing the necessary smart contracts to enable DAO governance on the
      platform, with a target launch in <strong>March-April 2025</strong>. This will allow users to participate in platform governance,
      ensuring that Bitstaker remains community-driven.<br/><br/>
      Additionally, we are exploring the potential development of a <strong>cross-chain bridge</strong> to make
      cross-chain staking
      easier and more accessible for our users. Although no specific timeline has been set for this development, it
      remains a key area of focus for future innovation.<br/><br/>
      <strong>4. TVL Growth: A 167% Increase</strong><br/><br/>
      Bitstaker’s <strong>Total Value Locked (TVL)</strong> has grown substantially this quarter, reaching <strong>$80
      million</strong>—a remarkable <strong>167%
      increase</strong> compared to the <strong>$30 million</strong> TVL from Q1 2024. This growth reflects the increasing trust and participation
      in our platform as more users engage with our staking services across multiple chains.<br/><br/>
      <strong>Looking Ahead</strong><br/><br/>
      As we move forward, Bitstaker remains committed to expanding our platform, deepening strategic partnerships, and
      delivering innovative solutions that empower our users. With upcoming projects like the DAO launch and the
      potential for a cross-chain bridge, we are excited about the future and look forward to continued growth in the
      DeFi space.<br/><br/>
      Thank you to our incredible community for your ongoing support. Stay tuned for more updates as we continue to
      build the future of decentralized staking together.
    </p>`,
    date: 'July 5, 2024',
    time: '6 min read time'
  },
  {
    id: 'ipfs',
    image: img6Src,
    title: "Bitstaker Integrates IPFS for Enhanced Decentralized Data Storage",
    text: `<p>
      At Bitstaker, we are committed to leveraging cutting-edge technology to provide our users with a secure and
      efficient staking platform. To further strengthen our infrastructure and ensure the highest level of data
      integrity, we are excited to announce the integration of <strong>IPFS</strong> (InterPlanetary File System) into
      our platform.<br/><br/>
      <strong>What is IPFS?</strong><br/><br/>
      <strong>IPFS</strong> is a decentralized file system designed to provide a more robust and distributed approach to
      storing and accessing data. Unlike traditional HTTP-based systems, which rely on centralized servers, IPFS uses a
      peer-to-peer network where data is stored and retrieved based on unique cryptographic hashes. This ensures that
      files can be stored across multiple nodes, increasing data redundancy and availability while reducing the risk of
      central points of failure.<br/><br/>
      By integrating IPFS, Bitstaker ensures that user data and platform information are stored in a <strong>decentralized,
      secure, and efficient manner</strong>. Each file stored on IPFS is identified by a unique hash (known as a Content
      Identifier, or CID), which allows data to be quickly and securely accessed by users across the network.
      Additionally, IPFS supports <strong>version control</strong>, which is essential for tracking updates to files
      over time.<br/><br/>
      <strong>Why IPFS Matters for Staking</strong><br/><br/>
      With the integration of <strong>IPFS</strong>, Bitstaker significantly enhances its decentralized infrastructure,
      particularly when it comes to storing critical staking data. IPFS offers several key benefits that align with the
      core principles of DeFi (Decentralized Finance):<br/><br/>
      <ol>
        <li><strong>Improved Data Availability</strong>: Files stored on IPFS can be replicated across multiple nodes,
          ensuring high availability and preventing data loss. This is crucial for our users, who need reliable access
          to staking pool information, rewards data, and platform updates.
        </li>
        <li><strong>Enhanced Security</strong>: By decentralizing data storage, IPFS reduces the risk of malicious
          attacks or data manipulation. Each file is protected by its unique CID, ensuring that the data remains
          tamper-proof and verifiable.
        </li>
        <li><strong>Reduced Costs</strong>: IPFS allows us to store large amounts of data off-chain while keeping only
          the essential data (such as the hash) on-chain. This reduces gas fees and storage costs for our users, making
          staking on Bitstaker more cost-effective.
        </li>
      </ol>
      Bitstaker’s integration of <strong>IPFS</strong> with <strong>Ethereum smart contracts</strong> allows us to
      achieve a powerful
      combination of decentralized data storage and on-chain logic. Here’s how the integration works:<br/><br/>
      How IPFS Integrates with Ethereum Smart Contracts<br/><br/>
      <ul>
        <li><strong>Data Hashes Stored On-Chain</strong>: When a file is uploaded to IPFS, it is assigned a CID. This
          CID is stored in the
          smart contract, providing a unique identifier for the data. The actual data remains on the IPFS network,
          accessible through the CID, while the blockchain stores only the hash to verify its integrity.
        </li>
        <li><strong>Efficient Storage and Retrieval</strong>: Users can access the data stored on IPFS using the CID,
          which is retrieved from
          the Ethereum smart contract. This ensures that the data is always available and can be fetched in a
          decentralized
          manner, even if some nodes are offline.
        </li>
        <li><strong>Security and Trust</strong>: IPFS, combined with the immutability of Ethereum smart contracts,
          provides a high level of
          security and transparency. Data stored on IPFS is resistant to censorship and tampering, while Ethereum
          ensures
          that the conditions of staking and rewards are executed fairly.
        </li>
      </ul>
      <br/><br/>
      <strong>Future of Decentralized Data at Bitstaker</strong><br/><br/>
      By integrating IPFS, Bitstaker is laying the groundwork for further decentralization and efficiency. As our
      platform evolves, we will continue to explore additional ways to leverage IPFS for secure data storage and
      retrieval, providing our users with a robust staking experience.<br/><br/>
      The combination of <strong>IPFS</strong> and <strong>Ethereum smart contracts</strong> represents the future of decentralized
      applications, and
      Bitstaker is proud to be at the forefront of this innovation. Our goal is to provide users with <strong>secure,
      transparent, and efficient</strong> staking solutions that protect their data and assets while offering high returns.<br/><br/>
      Stay tuned for more updates on how we are continuously improving our platform to meet the needs of our growing
      community.
    </p>`,
    date: 'May 28, 2024',
    time: '9 min read time'
  },
  {
    id: "review",
    image: img5Src,
    title: " Bitstaker 2024 Q1 Review: Strong Growth, New Partnerships, and a 114% TVL Increase",
    text: `<p>
      As we wrap up the first quarter of 2024, Bitstaker is proud to share our key achievements and growth milestones.
      The past quarter has been transformative, marked by significant user growth, strategic partnerships, and a
      remarkable increase in our total value locked (TVL). Here’s a look at our highlights for Q1 2024.
      <br/><br/>
      <strong>1. Impressive TVL Growth: A 114% Increase</strong><br/><br/>
      One of the most exciting developments this quarter is the substantial increase in Bitstaker’s Total Value Locked
      (TVL). Last quarter, we reported a TVL of $14 million, and this quarter, we reached an impressive $30 million.
      This represents a 114% increase in TVL, demonstrating the growing trust in our platform and the strength of our
      staking offerings. This increase reflects not only our expanding user base but also the increased staking activity
      across multiple chains.
      <br/><br/>
      <strong>2. ARB Staking Pool Launch and $1.5 Million TVL</strong><br/><br/>
      We successfully completed the ARB staking smart contract optimization and launched the ARB staking pool, which
      quickly garnered user interest. The pool’s TVL has already reached $1.5 million, showing strong demand for ARB
      staking and Bitstaker’s ability to deliver seamless staking services across diverse blockchain networks.
      <br/><br/>
      <strong>3. User Growth and Wallet Connections</strong><br/><br/>
      Our platform continues to see robust user growth, with independent active users surpassing 2,000 and connected
      wallets exceeding 4,500. This growth in user engagement showcases the expanding adoption of Bitstaker’s staking
      services. Our commitment to user satisfaction and platform stability is reflected in these numbers.
      <br/><br/>
      <strong>4. Strategic Partnerships with Chainlink, CoinMarketCap, and CoinGecko</strong><br/><br/>
      Partnerships played a key role in Bitstaker’s success this quarter. We teamed up with Chainlink, integrating their
      oracle service to provide accurate, reliable data to improve the staking experience for our users. This
      collaboration also led to the successful launch of the LINK staking pool, further diversifying the staking options
      on Bitstaker.
      <br/><br/>
      Additionally, we have partnered with CoinMarketCap and CoinGecko to integrate their APIs, ensuring a stable and
      consistent data service. These partnerships allow us to deliver real-time market data, helping users make informed
      staking decisions with confidence.
      <br/><br/>
      <strong>5. Expanding Staking Options: APT and Manta Network</strong><br/><br/>
      As part of our continued effort to provide more staking opportunities, we are deploying the APT staking smart
      contract and preparing to launch the Manta network staking pool in May 2024. These new additions will allow users
      to diversify their portfolios further and take advantage of even more staking options on Bitstaker.
      <br/><br/>
      <strong>6. Significant Investment in Growth</strong><br/><br/>
      Our expansion comes with continuous investment in platform development and security. The increase in TVL and user
      activity has required ongoing improvements in our infrastructure to ensure that Bitstaker remains secure,
      scalable, and user-friendly. We’ve allocated a significant portion of our resources to system optimization,
      security audits, and infrastructure upgrades to handle the rapid growth.
      <br/><br/>
      Looking Ahead<br/><br/>
      As we move forward in 2024, Bitstaker remains committed to expanding our platform, enhancing our user experience,
      and bringing more innovative staking solutions to our community. The 114% increase in TVL and growing partnerships
      signal a bright future ahead. With new staking pools, strategic collaborations, and increased user engagement, we
      are excited to continue this momentum and deliver even more value to our users.
      <br/><br/>
      Thank you to our ever-growing community for your trust and support as we continue to push the boundaries of
      decentralized finance.
      <br/><br/>
      This article covers Bitstaker’s Q1 2024 achievements, emphasizing the 114% TVL growth, strategic partnerships, and
      user growth. Let me know if you need further revisions or additional details!
    </p>`,
    date: 'April 7, 2024',
    time: '7 min read time'
  },
  {
    id: "partnerships",
    image: img3Src,
    title: "Bitstaker Strengthens Platform Stability Through Partnerships with CoinGecko and CoinMarketCap",
    text: `<p>
      At Bitstaker, we continuously strive to provide our users with the most reliable and seamless staking experience.
      To achieve this, forming strategic partnerships with industry leaders is essential. Among the most significant of
      these collaborations are our partnerships with CoinGecko and CoinMarketCap, two of the most trusted and widely
      used platforms in the cryptocurrency world.
      <br/><br/>
      <strong>Why These Partnerships Matter</strong>
      <br/><br/>
      As a decentralized finance (DeFi) staking platform, Bitstaker relies on real-time data to power our staking pools
      and provide users with accurate information on token prices, market movements, and staking
      options. <strong>CoinGecko</strong> and
      <strong>CoinMarketCap</strong> are at the forefront of crypto data aggregation, known for their comprehensive,
      up-to-the-minute
      information.
      <br/><br/>
      <strong>By integrating their APIs,</strong> Bitstaker ensures that users can access stable and consistent data,
      helping them make
      informed decisions. This collaboration enhances the overall user experience, offering reliable data for tracking
      token values, staking pool metrics, and general market trends.
      <br/><br/>
      <strong>Real-Time Market Data for Informed Staking Decisions</strong>
      <br/><br/>
      Staking requires users to be able to trust the data they see. With the integration of CoinGecko and CoinMarketCap
      APIs, Bitstaker provides its community with <strong>real-time price feeds,</strong> ensuring that token prices are
      always accurate.
      This real-time information is crucial for users who need to respond to market shifts and optimize their staking
      strategies.
      <br/><br/>
      For example, the value of assets in staking pools can fluctuate based on market movements. Thanks to our
      partnerships, users can confidently adjust their staked assets knowing they have the latest data at their
      fingertips. This transparency fosters trust and positions Bitstaker as a platform where users can confidently
      stake their assets for long-term returns.
      <br/><br/>
      <strong>Stability and Consistency</strong>
      <br/><br/>
      In a fast-moving industry like cryptocurrency, stable data is vital. Fluctuations and delays in data can lead to
      misinformed decisions and lost opportunities. Our partnerships with CoinGecko and CoinMarketCap ensure that
      Bitstaker users have <strong>consistent access to reliable data,</strong> even in volatile market conditions.
      These partnerships guarantee that the Bitstaker platform continues to run smoothly, providing users with a stable
      staking environment where they can focus on maximizing their returns without worrying about the accuracy of the
      data.
      <br/><br/>
      <strong>Looking Ahead</strong>
      <br/><br/>
      As Bitstaker continues to grow, we remain committed to leveraging our partnerships
      with <strong>CoinGecko</strong> and
      <strong>CoinMarketCap</strong> to further improve the staking experience for our users. Our goal is to remain a
      trusted platform
      where users have access to all the tools and data they need to make informed decisions.
      These collaborations are just one part of our broader mission to provide a secure, transparent, and user-centric
      DeFi staking platform. By working with industry-leading partners, we’re not only enhancing our current offerings
      but also preparing Bitstaker for continued success in the evolving world of decentralized finance.
    </p>`,
    date: 'February 7, 2024',
    time: '7 min read time'
  },
  {
    id: "partners",
    image: img4Src,
    title: "Bitstaker Partners with Chainlink to Enhance Staking and Launches LINK Staking Pools",
    text: `<p>
      At Bitstaker, our mission is to create a secure and user-friendly staking environment that provides real value to
      our community. To further enhance the user experience and optimize the staking process, we’ve partnered with
      <strong>Chainlink</strong>, the leading decentralized oracle provider. This strategic collaboration not only
      improves data
      reliability across our platform but has also enabled the successful launch of <strong>LINK staking pools</strong>
      on Bitstaker.
      <br/><br/>
      <strong>Why Chainlink?</strong>
      <br/><br/>
      Chainlink is widely recognized as the most reliable decentralized oracle network, known for its ability to provide
      secure, real-time, and tamper-proof data feeds to smart contracts. By integrating Chainlink’s oracle service,
      Bitstaker is able to deliver accurate and reliable staking data, ensuring that users can make informed decisions
      about their staking activities.
      <br/><br/>
      Chainlink’s oracles enhance our platform’s security by providing robust price feeds that help prevent manipulation
      and inaccuracies. This partnership strengthens Bitstaker’s foundation, giving our users confidence that the data
      they see and the rewards they earn are accurate and based on real-world conditions.
      <br/><br/>
      <strong>Introducing LINK Staking Pools</strong>
      <br/><br/>
      As part of this exciting partnership, Bitstaker is thrilled to announce the launch of LINK staking pools,
      providing users with a new way to earn rewards by staking their LINK tokens. With the backing of Chainlink’s
      oracle service, we’re offering four different LINK staking pools, each with specific terms to meet the needs of a
      diverse range of users:
      <br/><br/>
      <ul>
        <li><strong>14-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 500 - 2000 LINK</li>
        <li class='secondLi'>Ideal for users looking for short-term gains with a moderate staking commitment.</li>
        <li><strong>30-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 2000 - 5000 LINK</li>
        <li class='secondLi'>For users who want to lock in their tokens for a month and enjoy a higher reward
          opportunity.
        </li>
        <li><strong>60-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 3000 - 10,000 LINK</li>
        <li class='secondLi'>This mid-term option provides a balance between flexibility and returns, catering to
          users with larger
          holdings.
        </li>
        <li><strong>90-Day Staking Pool:</strong></li>
        <li class='secondLi'><strong>Limit</strong>: 5000 - 30,000 LINK</li>
        <li class='secondLi'>Designed for long-term stakers seeking the highest possible rewards, this pool is
          perfect for those
          committed
          to maximizing their LINK staking returns.
        </li>
      </ul>
      <br/><br/>
      These pools offer users the flexibility to choose their staking terms based on their individual goals and the size
      of their LINK holdings. Each pool is capped at specific limits to ensure fair distribution of rewards and maintain
      a balanced staking ecosystem.
      <br/><br/>
      <strong>The Benefits of Staking LINK on Bitstaker</strong>
      <br/><br/>
      By staking LINK on Bitstaker, users can take advantage of the combined security and reliability of Chainlink’s
      oracle services and Bitstaker’s robust staking infrastructure. The launch of these LINK staking pools provides our
      users with a new opportunity to earn rewards while benefiting from the real-time, accurate data feeds supplied by
      Chainlink.
      <br/><br/>
      Furthermore, the variety of staking pool options means users can select the duration and amount that best fits
      their strategy. Whether you&apos;re looking for short-term flexibility or long-term rewards, Bitstaker’s LINK
      staking
      pools offer something for everyone.
      <br/><br/>
      <strong>Looking Ahead</strong>
      <br/><br/>
      This partnership with Chainlink marks a significant step forward for Bitstaker as we continue to expand our
      platform and deliver better services to our users. With the integration of Chainlink’s trusted oracle network and
      the launch of LINK staking pools, we are enhancing both the security and profitability of staking on our platform.
      <br/><br/>
      We’re excited to see how our community engages with these new staking opportunities and look forward to continuing
      our collaboration with Chainlink to bring more innovative solutions to the Bitstaker platform.
    </p>`,
    date: 'February 25, 2024',
    time: '8 min read time'
  },
  {
    id: "quarter-review",
    image: img2Src,
    title: "Bitstaker First Quarter Review: A Strong Start and Promising Future",
    text: `<p>As we conclude our first quarter, Bitstaker is proud to reflect on the major milestones and achievements
      that have set the stage for our growth in the DeFi staking ecosystem. From strategic investments to expanding
      support across multiple chains, we are committed to delivering a secure and profitable staking experience for our
      users. Here’s a look back at what we’ve accomplished in just a few short months.
      <br/><br/>
      <strong>1. Institutional Investment and Early Growth</strong>
      Bitstaker has garnered strong support from the institutional sector, receiving a total investment of US$1 million
      from five different institutions. This capital has enabled us to expand our platform, develop new features, and
      solidify our presence in the decentralized finance (DeFi) space. Institutional backing is a strong vote of
      confidence in Bitstaker&apos;s mission and long-term potential, ensuring we have the resources to continue
      innovating.
      <br/><br/>
      <strong>2. Expanding Token and Staking Pool Support</strong>
      In just one quarter, Bitstaker has significantly expanded the range of tokens and staking pools available to our
      users. On the Ethereum chain, we now support 7 tokens across more than 30 staking pools, offering diverse options
      for Ethereum-based asset holders to maximize their returns.
      <br/><br/>
      Meanwhile, our presence on the BNB Chain has also seen tremendous growth. Bitstaker currently supports 5 tokens on
      the BNB Chain, with more than 20 staking pools available. This has created more opportunities for our users to
      stake their assets and earn consistent rewards in a safe and secure environment.
      <br/><br/>
      <strong>3. Impressive TVL Growth on the BNB Chain</strong>
      The Total Value Locked (TVL) on the BNB Chain has grown at a remarkable pace. Early in the quarter, we surpassed
      the $2 million TVL mark, and shortly after, we hit a new milestone of $12 million in TVL on the BNB Chain. This
      rapid growth underscores the trust users have in Bitstaker and the increasing popularity of staking on our
      platform.
      <br/><br/>
      <strong>4. ARB Chain Expansion and New Opportunities</strong>
      As part of our mission to support more blockchain networks and provide even greater flexibility to our users,
      Bitstaker has deployed the ARB Chain smart contract. This strategic move will soon allow users to stake ARB
      tokens, opening up new earning opportunities. Our team is finalizing the development of the ARB Staking Pool,
      which will launch in the near future, further broadening our platform’s capabilities.
      <br/><br/>
      <strong>5. Commitment to Security</strong>
      Security has always been a top priority at Bitstaker, and we are proud to announce that our platform has
      successfully passed a thorough security audit. This audit ensures that Bitstaker meets the highest security
      standards, providing our users with the peace of mind that their assets are protected. As we continue to grow, we
      remain committed to maintaining robust security protocols and constantly improving the safety of our platform.
      <br/><br/>
      <strong>Looking Ahead</strong>
      The first quarter has been a period of tremendous growth and innovation for Bitstaker, but this is only the
      beginning. With strong institutional support, an expanding range of tokens and staking pools, and continued
      success on multiple chains, we are poised for even greater achievements in the months to come.
      <br/><br/>
      As we prepare to launch the ARB Staking Pool and introduce more tokens and pools, we want to thank our community
      for their trust and support. Bitstaker remains dedicated to providing a secure, user-friendly, and profitable
      staking experience for all.
      <br/><br/>
      Stay tuned for more exciting updates as we continue to build a platform that empowers our users and redefines
      staking in the DeFi space.
    </p>`,
    date: 'January 5, 2024',
    time: '5 min read time'
  },
]