import img1 from "assets/icons/chains/8.svg"

export const poolDataPol = [
  {
    icon: img1,
    name: "Polygon",
    token: "POL",
    network: "Polygon",
    id: "polygon-ecosystem-token",
    addresses: [
      "0x01ce88498ed095d386e09834d32fd8f1fecd184a",
      "0x980868189786e79ca3e8f1e5f98ef6a4f8fd1880",
      "0xb55703d907c93cfc1632c994a0202e42877c07ff",
      "0x61063a2942bdf99033d4a29b7b1e3e91c92621ff",
    ],
    quantity: ["15000-30000", "25000-40000", "40000-100000", "100000-300000"],
    percents: ["1.5", "3.7", "9.3", "16"],
    days: ["14", "30", "60", "90"],
  },
]
