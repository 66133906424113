import img1 from "assets/icons/chains/4.svg"

const tokensText = [
  {
    token: "ARB",
    tokenDesc: "Arbitrum is an Ethereum layer-two (L2) scaling solution. It uses optimistic rollups to achieve its goal of improving speed, scalability and cost-efficiency on Ethereum. Arbitrum benefits from the security and compatibility of Ethereum. Another benefit is the higher throughput and lower fees compared to Ethereum. That is made possible thanks to moving most of the computation and storage load off-chain.",
    site: "https://arbitrum.io/"
  },
];

export const poolDataSeparatedArb = [
  {
    icon: img1,
    name: "Arbitrum One",
    token: "ARB",
    network: "Arbitrum One",
    id: "arbitrum",
    address: "0x83a93621f11922434445cf1914edd55674d6fc56",
    addAddress: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    quantity: "20000-40000",
    index: 1,
    percent: "1.2",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Arbitrum One",
    token: "ARB",
    network: "Arbitrum One",
    id: "arbitrum",
    address: "0x544cbe6698e2e3b676c76097305bba588defb13a",
    addAddress: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    quantity: "40000-60000",
    percent: "3.1",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Arbitrum One",
    token: "ARB",
    network: "Arbitrum One",
    id: "arbitrum",
    address: "0x97772ff955ce7c136f9d7db5414e197da25a9c72",
    addAddress: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    quantity: "50000-75000",
    percent: "7.9",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Arbitrum One",
    token: "ARB",
    network: "Arbitrum One",
    id: "arbitrum",
    address: "0x36575e3dc451e2d673d886ae8e11efb9707aa61f",
    addAddress: "0x912ce59144191c1204e64559fe8253a0e49e6548",
    quantity: "75000-200000",
    percent: "13.5",
    day: "90",
    text: tokensText[0],
    index: 4
  },
]
