import img1 from "assets/icons/chains/10.svg"

export const poolDataManta = [
  {
    icon: img1,
    name: "Manta",
    token: "MANTA",
    network: "Manta",
    id: "manta-network",
    addresses: [
      "0x51619bc93ab9252fb17cce44a4e4971d42dfca34",
      "0x38060c598af2de576b950faa846e83e2fa7b19e9",
      "0xbf67108c1d2ec211e38976b11f4952508181742f",
      "0x06018c2e3089fa1abb23f253c56b9cbc86096e4d",
    ],
    quantity: ["7000-14000", "14000-42000", "28000-70000", "100000-180000"],
    percents: ["1.5", "3.8", "9.4", "16"],
    days: ["14", "30", "60", "90"],
  },
]
