import toast from "react-hot-toast"

export const copyToClipboard = (text: string) => {
  const elem = document.createElement("textarea")
  elem.value = text
  document.body.appendChild(elem)
  elem.select()
  document.execCommand("copy")
  document.body.removeChild(elem)

  toast.success("Copied!")
}