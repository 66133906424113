import img1 from "assets/icons/pool/eth.svg"
import img2 from "assets/icons/pool/tether.svg"
import img3 from "assets/icons/pool/usdc.svg"
import img5 from "assets/icons/pool/uni.svg"
import img6 from "assets/icons/pool/link.svg"
import img7 from "assets/icons/pool/wbtc.png"
import img8 from "assets/icons/pool/ape.png"
import img11 from "assets/icons/pool/bnb.png"
import img12 from "assets/icons/pool/busd.webp"
import img13 from "assets/icons/pool/cake.png"
import img15 from "assets/icons/pool/baby-doge.png"
import img16 from "assets/icons/pool/trust-wallet.png"
import img17 from "assets/icons/pool/gmt.webp"
import img18 from "assets/icons/pool/c98.png"
import img19 from "assets/icons/chains/3.svg"
import img20 from "assets/icons/chains/4.svg"
import img21 from "assets/icons/chains/6.svg"
import img22 from "assets/icons/chains/7.svg"
import img23 from "assets/icons/chains/5.svg"
import img24 from "assets/icons/chains/10.svg"
import img25 from "assets/icons/chains/8.svg"
import img26 from "assets/images/sol.png"

export const allCoins = [
  {
    id: "ethereum",
    icon: img1,
  },
  {
    id: "tether",
    icon: img2,
  },
  {
    id: "usd-coin",
    icon: img3,
  },
  {
    id: "uniswap",
    icon: img5,
  },
  {
    id: "solana",
    icon: img26,
  },
  {
    id: "chainlink",
    icon: img6,
  },
  {
    id: "wrapped-bitcoin",
    icon: img7,
  },
  {
    id: "apecoin",
    icon: img8,
  },
  {
    id: "binancecoin",
    icon: img11,
  },
  {
    id: "binance-usd",
    icon: img12,
  },
  {
    id: "pancakeswap-token",
    icon: img13,
  },
  {
    id: "baby-doge-coin",
    icon: img15,
  },
  {
    id: "trust-wallet-token",
    icon: img16,
  },
  {
    id: "stepn",
    icon: img17,
  },
  {
    id: "coin98",
    icon: img18,
  },
  {
    id: "optimism",
    icon: img19,
  },
  {
    id: "arbitrum",
    icon: img20,
  },
  {
    id: "avalanche-2",
    icon: img21,
  },
  {
    id: "ethereum",
    icon: img22,
  },
  {
    id: "fantom",
    icon: img23,
  },
  {
    id: "manta-network",
    icon: img24,
  },
  {
    id: "polygon-ecosystem-token",
    icon: img25,
  },
]