import React, {useState} from "react"

import img1 from "assets/images/news-1.png"
import img2 from "assets/images/news-2.png"
import img3 from "assets/images/news-3.png"
import "./NewsHome.scss"
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../utils";

export const NewsHome = (): JSX.Element => {
  const blocks = [
    {
      title: "Bitstaker Secures $4 Million Seed Round, Paving the Way for DeFi Innovation",
      desc: "United States, 9th Sep 2024 - Bitstaker has taken a bold step in reshaping the decentralized finance (DeFi) landscape, securing $4 million in seed funding. This significant investment marks a pivotal moment for the platform, as it gains the trust of key investors and sets the stage for groundbreaking developments in the DeFi ecosystem.",
      link: "https://www.digitaljournal.com/pr/news/revupmarketer/bitstaker-secures-4-million-seed-1740095196.html",
      source: "Digital Journal",
      icon: img3,
    },
    {
      title: "Bitstaker Raises Seed Funding to Propel Its Growth in DeFi Staking Innovation",
      desc: "Bitstaker, a rapidly emerging decentralized finance (DeFi) staking platform, has reached a major milestone by securing significant seed financing. This achievement highlights the growing confidence investors have in Bitstaker’s potential to lead the next wave of innovation in the DeFi space, particularly within the highly competitive staking market. With this new capital, the platform is well-positioned to accelerate its development and deliver cutting-edge solutions that could redefine the future of decentralized finance.",
      icon: img2,
      link: "https://www.binance.com/en/square/post/13511364826689",
      source: 'Binance',
    },
    {
      title: "Bitstaker Achieves $165 Million TVL Milestone, Innovates with Cutting-Edge Technology",
      desc: "In just three weeks since its launch, Bitstaker has surpassed $165 million in total value locked (TVL), establishing itself as a leading platform in the decentralized finance (DeFi) staking sector. This remarkable growth underscores Bitstaker's commitment to advancing the DeFi landscape with innovative features and robust technology.",
      link: "https://coinmarketcap.com/community/articles/66def904ef79387705a9d114/",
      source: 'CoinMarketCap',
      icon: img1,
    },
  ]
  return (
    <div className="news-home">
      <h3 data-aos="fade-up" className="news-home-title">In the News</h3>
      <div data-aos="fade-up" className="news-home-content">
        {blocks.map((block, index) => (
          <a href={block.link} target='_blank' rel='noreferrer noopener' key={index} className="news-home-content-item">
            <div className="news-home-content-item-icon">
              <img alt="news-home" src={block.icon}/>
            </div>
            <div className="news-home-content-item-title">{block.title}</div>
            <div className="news-home-content-item-desc">{block.desc}</div>
            <div className="news-home-content-item-footer">From <b>{block.source}</b></div>
          </a>
        ))}
      </div>
      <div className='news-home-btn'>
        <SimpleButton text='More Crypto News' href={routes.news} variant='colored'/>
      </div>
    </div>
  )
}
