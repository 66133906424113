import img1 from "assets/icons/chains/3.svg"

const tokensText = [
  {
    token: "OP",
    tokenDesc: 'Optimism (OP) is a layer-two blockchain on top of Optimism. Optimism benefits from the security of the Optimism mainnet and helps scale the Optimism ecosystem by using optimistic rollups. That means transactions are trustlessly recorded on Optimism but ultimately secured on Optimism.',
    site: "https://www.optimism.io/"
  },
];

export const poolDataSeparatedOpt = [
  {
    icon: img1,
    name: "Optimism",
    token: "OP",
    network: "Optimism",
    id: "optimism",
    address: "0x00080706a7d99cbc163d52dcf435205b1ad940d1",
    addAddress: "0x4200000000000000000000000000000000000042",
    quantity: "7000-10000",
    index: 1,
    percent: "1.4",
    day: "14",
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Optimism",
    token: "OP",
    network: "Optimism",
    id: "optimism",
    address: "0xd25711edfbf747efce181442cc1d8f5f8fc8a0d3",
    addAddress: "0x4200000000000000000000000000000000000042",
    quantity: "10000-30000",
    percent: "3.5",
    day: "30",
    index: 2,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Optimism",
    token: "OP",
    network: "Optimism",
    id: "optimism",
    address: "0xb533c12fb4e7b53b5524eab9b47d93ff6c7a456f",
    addAddress: "0x4200000000000000000000000000000000000042",
    quantity: "20000-50000",
    percent: "8.7",
    day: "60",
    index: 3,
    text: tokensText[0],
  },
  {
    icon: img1,
    name: "Optimism",
    token: "OP",
    network: "Optimism",
    id: "optimism",
    address: "0x8ed1609d796345661d36291b411992e85de7b224",
    addAddress: "0x4200000000000000000000000000000000000042",
    quantity: "50000-100000",
    percent: "14.8",
    day: "90",
    text: tokensText[0],
    index: 4
  },
]
